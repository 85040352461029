import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const StateContext = createContext();
const initialState = {
  chat: false,
  userProfile: false,
  notification: false,
};

const empty = {};

export const ContextProvider = ({ children }) => {
  const [apiBaseUrl, setApiBaseUrl] = useState("https://prodapi.myvalidus.com/Vapi");

  const [investorData, setInvestorData] = useState([]);
  const [folioData, setFolioData] = useState(
    JSON.parse(sessionStorage.getItem("folioData")) || []
  );
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [screenSize, setScreenSize] = useState(undefined);

  const [formData, setFormData] = useState({});
  const [accountNumber, setAccountNumber] = useState("");

  const [loggedIn, setLoggedIn] = useState(
    sessionStorage.getItem("loggedIn") || false
  );

  const [user_name, setUser_name] = useState(
    sessionStorage.getItem("user_name") || ""
  );
  const [user_first_name, setUser_first_name] = useState(
    sessionStorage.getItem("user_first_name") || ""
  );
  const [user_last_name, setUser_last_name] = useState(
    sessionStorage.getItem("user_last_name") || ""
  );
  const [user_branch_id, setUser_branch_id] = useState(
    sessionStorage.getItem("user_branch_id") || ""
  );
  const [first_time_user, setFirst_time_user] = useState(
    sessionStorage.getItem("first_time_user") || ""
  );
  const [pwd_expiry, setPwd_expiry] = useState(
    sessionStorage.getItem("pwd_expiry") || ""
  );
  const [last_login_date, setLast_login_date] = useState(
    sessionStorage.getItem("last_login_date") || ""
  );
  const [user_id, setUser_id] = useState(
    sessionStorage.getItem("user_id") || ""
  );
  const [user_role_id, setUser_role_id] = useState(
    sessionStorage.getItem("user_role_id") || ""
  );
  const [value, setValue] = useState("");
  const [fund, setFund] = useState("");
  const [deviceIP, setDeviceIP] = useState("");
  const [branchData, setBranchData] = useState({});
  const [userBranch, setUserBranch] = useState({});

  const handleClick = (clicked) => {
    setIsClicked({
      ...initialState,
      [clicked]: !isClicked[clicked],
    });
  };

  const formatNumberWithCommas = (num) => {
    // Check if the number is a valid number or convert it to a string
    num = typeof num === "number" ? num.toString() : num || "";

    // Split the number into integer and decimal parts
    const parts = num.split(".");
    let integerPart = parts[0];
    const decimalPart = parts[1] ? `.${parts[1]}` : "";

    // Add commas to the integer part
    if (integerPart.length > 3) {
      const integerLength = integerPart.length;
      const numCommas = Math.floor((integerLength - 1) / 3);
      for (let i = 0; i < numCommas; i++) {
        const commaIndex = integerLength - 3 * (i + 1) + i;
        integerPart =
          integerPart.slice(0, commaIndex) +
          "," +
          integerPart.slice(commaIndex);
      }
    }

    // Combine the integer and decimal parts
    return integerPart + decimalPart;
  };

  const formattedAmountWithCommas = (
    num,
    symbol
  ) => {
    const hasNegativeSign = num ? num.toString().startsWith('-') : false;
    num = num?.toString().replace('-', '').split('.')[0] ?? '';

    if (num.length <= 3) {
      return symbol
        ? `INR ${hasNegativeSign ? '-' : ''}${num}`
        : `${hasNegativeSign ? '-' : ''}${num}`;
    } else if (num.length <= 4) {
      return symbol
        ? `INR ${hasNegativeSign ? '-' : ''}${num.substring(0, 1)},${num.substring(
          1,
        )}`
        : `${hasNegativeSign ? '-' : ''}${num.substring(0, 1)},${num.substring(
          1,
        )}`;
    } else if (num.length <= 5) {
      return symbol
        ? `INR ${hasNegativeSign ? '-' : ''}${num.substring(0, 2)},${num.substring(
          2,
        )}`
        : `${hasNegativeSign ? '-' : ''}${num.substring(0, 2)},${num.substring(
          2,
        )}`;
    } else if (num.length <= 6) {
      return symbol
        ? `INR ${hasNegativeSign ? '-' : ''}${num.substring(0, 1)},${num.substring(
          1,
          3,
        )},${num.substring(3)}`
        : `${hasNegativeSign ? '-' : ''}${num.substring(0, 1)},${num.substring(
          1,
          3,
        )},${num.substring(3)}`;
    } else if (num.length <= 7) {
      return symbol
        ? `INR ${hasNegativeSign ? '-' : ''}${num.substring(0, 2)},${num.substring(
          2,
          4,
        )},${num.substring(4)}`
        : `${hasNegativeSign ? '-' : ''}${num.substring(0, 2)},${num.substring(
          2,
          4,
        )},${num.substring(4)}`;
    } else if (num.length <= 8) {
      return symbol
        ? `INR ${hasNegativeSign ? '-' : ''}${num.substring(0, 1)},${num.substring(
          1,
          3,
        )},${num.substring(3, 5)},${num.substring(5)}`
        : `${hasNegativeSign ? '-' : ''}${num.substring(0, 1)},${num.substring(
          1,
          3,
        )},${num.substring(3, 5)},${num.substring(5)}`;
    } else if (num.length <= 9) {
      return symbol
        ? `INR ${hasNegativeSign ? '-' : ''}${num.substring(0, 2)},${num.substring(
          2,
          4,
        )},${num.substring(4, 6)},${num.substring(6)}`
        : `${hasNegativeSign ? '-' : ''}${num.substring(0, 2)},${num.substring(
          2,
          4,
        )},${num.substring(4, 6)},${num.substring(6)}`;
    } else if (num.length <= 10) {
      return symbol
        ? `INR ${hasNegativeSign ? '-' : ''}${num.substring(0, 3)},${num.substring(
          3,
          5,
        )},${num.substring(5, 7)},${num.substring(7)}`
        : `${hasNegativeSign ? '-' : ''}${num.substring(0, 3)},${num.substring(
          3,
          5,
        )},${num.substring(5, 7)},${num.substring(7)}`;
    } else {
      return symbol
        ? `INR ${hasNegativeSign ? '-' : ''}${num}`
        : `${hasNegativeSign ? '-' : ''}${num}`;
    }
  };
  const [loading, setLoading] = useState(true);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const getdeviceInfo = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setDeviceIP(res.data.IPv4);
  };

  useEffect(() => {
    getdeviceInfo();
  }, [user_name]);

  let navigate = useNavigate();
  const handleLogOut = () => {
    axios
      .get(`${apiBaseUrl}/VUser/logout/${sessionStorage.getItem("user_name")}`)
      .then((res) => {
        setLoggedIn(false);
        sessionStorage.removeItem("loggedIn");
        sessionStorage.removeItem("user_name");
        sessionStorage.removeItem("user_first_name");
        sessionStorage.removeItem("user_last_name");
        sessionStorage.removeItem("user_branch_id");
        sessionStorage.removeItem("first_time_user");
        sessionStorage.removeItem("pwd_expiry");
        sessionStorage.removeItem("last_login_date");
        sessionStorage.removeItem("user_id");
        sessionStorage.removeItem("user_role_id");
        setUser_name("");
        setUser_first_name("");
        setUser_last_name("");
        setUser_branch_id("");
        setFirst_time_user("");
        setPwd_expiry("");
        setLast_login_date("");
        setUser_id("");
        setUser_role_id("");
        setBranchData({});
        setDeviceIP("");
      })
      .then(() => {
        navigate("/Login");
      });
  };


  return (
    <StateContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        value,
        setValue,
        loading,
        setLoading,
        fund,
        setFund,
        deviceIP,
        setDeviceIP,

        user_name,
        user_first_name,
        user_last_name,
        user_branch_id,
        first_time_user,
        pwd_expiry,
        last_login_date,
        user_id,
        user_role_id,

        setUser_name,
        setUser_first_name,
        setUser_last_name,
        setUser_branch_id,
        setFirst_time_user,
        setPwd_expiry,
        setLast_login_date,
        setUser_id,
        setUser_role_id,
        branchData,
        setBranchData,
        investorData,
        setInvestorData,
        userBranch,
        setUserBranch,
        folioData,
        setFolioData,
        activeMenu,
        setActiveMenu,
        isClicked,
        handleClick,
        screenSize,
        setScreenSize,
        empty,
        apiBaseUrl,
        setApiBaseUrl,
        formData,
        setFormData,

        accountNumber,
        setAccountNumber,
        scrollToTop,
        formatNumberWithCommas,
        formattedAmountWithCommas,
        handleLogOut,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
