import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { Tooltip, Modal } from "antd";
import { useStateContext } from "../contexts/ContextProvider";
// import { AiOutlineDown, AiOutlineUp, AiOutlineHome } from "react-icons/ai";
// import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import "./custom.css";
import "antd/dist/antd.css";
import logo from "../assets/validuslogo.png";
// import { HiOutlineCurrencyRupee } from "react-icons/hi";
// import { BsArrowLeftRight } from "react-icons/bs";
import { LogoutOutlined } from "@ant-design/icons";
// import { FaMoneyBillAlt } from "react-icons/fa";
import moment from "moment";

const Sidebar = () => {
  const {
    activeMenu,
    setActiveMenu,
    last_login_date,
    handleLogOut,
    user_first_name,
    handleCloseSidebar,
    user_last_name,
  } = useStateContext();
  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white-900 dark:text-blue-400 text-md m-2 bg-validus-blue-1";

  const normalLink =
    "group flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-white dark:text-gray-700 dark:hover:text-black hover:bg-light-gray hover:text-black dark:hover:text-taurus-red m-2 bg-validus-blue-1";

  const links = [
    {
      links: [
        {
          displayName: "Transactions",
          name: "Transactions",
          icon: (
            <img
              src={require("../assets/transaction.png")}
              alt="Transactions"
              width={23}
              height={23}
            />
          ),
        },
        // {
        //   displayName: "SIP",
        //   name: "SIP",
        //   icon: (
        //     <img
        //       src={require("../assets/sip.png")}
        //       alt="sip"
        //       width={23}
        //       height={23}
        //     />
        //   ),
        // },
        // {
        //   displayName: "Redemption",
        //   name: "Redemption",
        //   icon: (
        //     <img
        //       src={require("../assets/redemption copy.png")}
        //       alt="redemption"
        //       width={23}
        //       height={23}
        //     />
        //   ),
        // },
        // {
        //   displayName: "Switch",
        //   name: "Switch",
        //   icon: (
        //     <img
        //       src={require("../assets/switch.png")}
        //       alt="switch"
        //       width={23}
        //       height={23}
        //     />
        //   ),
        // },
        {
          displayName: "Fund Documents",
          name: "FundDocuments",
          icon: (
            <img
              src={require("../assets/Statement 4.png")}
              alt="fundDocs"
              width={23}
              height={23}
            />
          ),
        },
        {
          displayName: "Service Requests",
          name: "ServiceRequests",
          icon: (
            <img
              src={require("../assets/request.png")}
              alt="request"
              width={23}
              height={23}
            />
          ),
        },
        {
          displayName: "Profile",
          name: "Profile",
          icon: (
            <img
              src={require("../assets/userProfile.png")}
              alt="userProfile"
              width={23}
              height={23}
            />
          ),
        },
      ],
    },
  ];

  const [activeTitle, setActiveTitle] = useState(null);

  const handleLogoutFromSite = () => {
    Modal.confirm({
      title: "Are you sure you want to logout?",
      icon: <LogoutOutlined />,
      content: "You will be logged out from the Application.",
      okText: "Yes",
      cancelText: "No",
      onOk: () => handleLogOut(),
    });
  };

  return (
    <div
      className="h-screen
        lg:z-50 md:z-50 sm:z-50
        md:overflow-hidden overflow-auto
        md:hover:overflow-auto pb-10  bg-taurus-blue-bg-1"
      style={{ backgroundColor: "#B3C6E7", overflowY: "scroll" }}
    >
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSidebar}
              style={{ margin: "0 auto" }}
              className="items-center gap-3 mt-1 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <img
                style={{
                  width: "70%",
                  margin: "0 auto",
                }}
                src={logo}
                alt="company logo"
              />
            </Link>
            <Tooltip placement="bottom" title={"close"}>
              <button
                type="button"
                onClick={() =>
                  setActiveMenu((prevActiveMenu) => !prevActiveMenu)
                }
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </Tooltip>
          </div>
          <h4 className="mt-5 text-center" style={{ color: "#000" }}>
            Hello {user_first_name} {user_last_name}
          </h4>
          <div
            className="mt-1 text-center"
            style={{ fontSize: 13, color: "#000" }}
          >
            Your last logged in time was on <br />
            {moment(last_login_date).format("D MMMM YYYY")} at{" "}
            {moment(last_login_date).format("hh:mm A")}
          </div>

          <div className="mt-5">
            {links.map((item) => {
              const isTitleActive = activeTitle === item.title;
              return (
                <div key={item.title}>
                  {item.links.map((link) => (
                    <NavLink
                      to={`/${link.name}`}
                      key={link.name}
                      onClick={handleCloseSidebar}
                      className={({ isActive }) =>
                        isActive && isTitleActive ? activeLink : normalLink
                      }
                    >
                      <div>{link.icon}</div>
                      <span className="capitalize">{link.displayName}</span>
                    </NavLink>
                  ))}
                </div>
              );
            })}
          </div>

          <div
            onClick={handleLogoutFromSite}
            style={{
              backgroundColor: "#002060",
              display: "flex",
              alignItems: "center",
              padding: "3.4%",
              paddingBottom: "4%",
              margin: "8px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            <div style={{ color: "white", marginLeft: "3%" }}>
              <LogoutOutlined />
            </div>
            <span
              className="capitalize"
              style={{ color: "white", marginLeft: "11.5%" }}
            >
              Log Out
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
