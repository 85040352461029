import React from "react";

const ThirdHolderDetails = (props) => {
  const { thirdHolderData } = props;

  const InfoItem = ({ label, value }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-betwen",
      }}
    >
      <p style={{ width: "35%", textAlign: "left" }}>{label}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "65%",
        }}
      >
        <div>:</div>
        <div style={{ marginLeft: "3%", textAlign: "left" }}>
          <b>{value}</b>
        </div>
      </div>
    </div>
  );
  const DateInfoItem = ({ label, date }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-betwen",
      }}
    >
      <p style={{ width: "35%", textAlign: "left" }}>{label}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "65%",
        }}
      >
        <div>:</div>
        <div style={{ marginLeft: "3%" }}>
          <b>
            {" "}
            {date
              ? new Date(date).toLocaleDateString("en-GB")
              : "Not Available"}
          </b>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      {thirdHolderData?.length > 0 ? (
        <>
          <div style={{ fontWeight: "bold" }}>Second Joint Holder</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "2%",
                width: "50%",
              }}
            >
              <InfoItem
                label="Name"
                value={`${thirdHolderData[0]?.account_Holder?.first_name} `}
              />
              <InfoItem
                label="Gender"
                value={
                  thirdHolderData[0]?.account_Holder?.gender_id === 1
                    ? "Male"
                    : thirdHolderData[0]?.account_Holder?.gender_id === 2
                    ? "Female"
                    : "Others"
                }
              />
              <InfoItem
                label="Relation"
                value={
                  thirdHolderData[0]?.holder_relationship_type_id === 1
                    ? "Son"
                    : thirdHolderData[0]?.holder_relationship_type_id === 2
                    ? "Father"
                    : thirdHolderData[0]?.holder_relationship_type_id === 3
                    ? "Mother"
                    : thirdHolderData[0]?.holder_relationship_type_id === 4
                    ? "Wife"
                    : thirdHolderData[0]?.holder_relationship_type_id === 5
                    ? "Husband"
                    : thirdHolderData[0]?.holder_relationship_type_id === 6
                    ? "Court Appointed"
                    : thirdHolderData[0]?.holder_relationship_type_id === 7
                    ? "Daughter"
                    : thirdHolderData[0]?.holder_relationship_type_id === null
                    ? "Not Available"
                    : "Others"
                }
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "2%",
                width: "50%",
              }}
            >
              <DateInfoItem
                label="Date of birth"
                date={thirdHolderData[0]?.account_Holder?.dob}
              />

              <InfoItem
                label="PAN Number"
                value={
                  thirdHolderData[0]?.prooflist &&
                  thirdHolderData[0]?.prooflist[0]?.proof_value ? (
                    <span>{thirdHolderData[0]?.prooflist[0]?.proof_value}</span>
                  ) : (
                    <span>Not Available</span>
                  )
                }
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ThirdHolderDetails;
