import React from 'react'
import './leftnav.css'
import statementmailback from '../assets/Mailback.jpg';
import nav from '../assets/NAV.jpg';
import servicerequest from '../assets/Service Request.jpg';
// import addnominee from '../assets/Nominee.jpg';
import drawdowncalendar from '../assets/Calendar.png';
import Transactionstatement from '../assets/Status.png';
// import requestcallback from '../assets/Call back.png';
import branchlocator from '../assets/Location.png';

const  Box = ({ linkTo, src, alt }) =>{
  return (
    <div style={{ backgroundColor: '#fff', width: 100, height: 100, margin: 10, borderRadius: 10}}  className="hoverable">
      <a href={linkTo} target='_blank' rel="noreferrer" style={{ textDecoration: 'none', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        {alt === 'About Validus' ? 
        <img src={src} alt={alt} width={60} height={60} />
         : <img src={src} alt={alt} width={60} height={60}   />}
      </a>
      <div style={{textAlign:'center', marginTop: '2%',color: 'white'}}>
        {alt}
      </div>
    </div>
  );
}

const LeftNav = () => {
  return (
    <div style={{width: '75%'}}  className='background'>
      <div className='left-heading'>
        Quick Services
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '3%'}}>
        <Box linkTo="/StatementMailBack" src={statementmailback} alt="Statement Mail Back"  />
        <Box linkTo="/NavHistory" src={nav} alt="NAV"  />
        <Box linkTo="/ServiceRequest" src={servicerequest} alt="Service Request" />
        {/* <Box linkTo="/page4" src={addnominee} alt="addnominee"  /> */}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center',marginTop: '10%'}}>
        <Box linkTo="/DrawdownCalendar" src={drawdowncalendar} alt="Drawdown Calendar"  />
        <Box linkTo="/TransactionStatus" src={Transactionstatement} alt="Transaction Status"  />
        {/* <Box linkTo="/page3" src={requestcallback} alt="requestcallback" /> */}
        <Box linkTo="https://www.validusfintech.com/contact-us/" src={branchlocator} alt="Branch Locator"  />
      </div>

      <div className='footer'>Looking for something else?</div>
      <div className='footer-1'>Call us on
        <span className='ml-2'>
          <a href='tel:91002 22569' style={{ color: '#fff', textDecoration: 'none' }}>
            91002 22569
          </a>
        </span>
      </div>
      <div className='footer-1'>Toll Free
        <span className='ml-2'>
          <a href='tel:18008893011' style={{ color: '#fff', textDecoration: 'none' }}>
            18008893011
          </a>
        </span>
      </div>
      <div className='footer-2'>Write to us at
        <span  className='ml-2'>
          <a href='mailto:investorcare@validusfintech.com' style={{ color: '#fff', textDecoration: 'none' }}>
          investorcare@validusfintech.com
          </a>
        </span>
      </div>
    </div>
  )
}

export default LeftNav