import React, { useState, useEffect } from "react";
import { Table, Select, Button, Input, Space } from "antd";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useStateContext } from "../../contexts/ContextProvider";
const InteractionHistory = () => {
  const { apiBaseUrl, folioData } = useStateContext();
  const [nctData, setNctData] = useState([]);
  const [nctTableData, setNctTableData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [disableGetButton, setDisableGetButton] = React.useState(true);

  useEffect(() => {
    nctData.length !== 0 &&
      nctData.forEach((ele, index) => {
        ele.sno = index + 1;
        ele.nct_transaction_date = moment(ele.nct_transaction_date)
          .format("DD/MM/YYYY")
          .toString();
        ele.nct_status =
          ele.nct_status === "Open"
            ? "Pending"
            : ele.nct_status === "Closed"
            ? "Success"
            : ele.nct_status === "Resolved"
            ? "Resolved"
            : "Null";
        ele.nct_old_value =
          ele.nct_category_id === 1 ? null : ele.nct_subject_id === 154 ||
            ele.nct_subject_id === 155 ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === "1"
                ? "Yes"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === "2"
                ? "No"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ""
                ? "Not Available"
                : "Not Applicable"}
            </>
          ) : ele?.nct_subject_id === 1 || ele?.nct_subject_id === 2 ? (
            <div style={{ color: "black" }}>
              <span>RM Name:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[0]}
              <br />
              <span>Distributor Name:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[2]}
              <br />
              <span>Distributor ARN-Code:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[3]}
              <br />
              <span>Distributor Mobile Number:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[6]}
              <br />
              <span>Distributor Email Address: </span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[5]}
              <br />
              <span>Distributor EUIN No:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[4]}
            </div>
          ) : ele?.nct_subject_id === 127 || ele?.nct_subject_id === 128 ? (
            <div style={{ color: "black" }}>
              <span>Bank Name:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[0]}
              <br />
              <span>Bank Account Number:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[1]}
              <br />
              <span>Bank MICR Code:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[2]}
              <br />
              <span>Bank IFSC Code:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[3]}
              <br />
              <span>Bank Account Type:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[4]}
            </div>
          ) : ele?.nct_subject_id === 75 ||
            ele?.nct_subject_id === 76 ||
            ele?.nct_subject_id === 77 ||
            ele?.nct_subject_id === 78 ? (
            <div style={{ color: "black" }}>
              <span>Address Line 1:</span>:{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[0]}
              <br />
              <span>Address Line 2:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[1]}
              <br />
              <span>City:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[2]}
              <br />
              <span>State:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[3]
                ? Object.values(JSON.parse(ele.nct_old_value))[3]
                : "Not Available"}
              <br />
              <span>Pincode:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[4]}
              <br />
              <span>Country:</span>{" "}
              {Object.values(JSON.parse(ele.nct_old_value))[5]}
            </div>
          ) : ele.nct_subject_id === 28 ||
            ele.nct_subject_id === 48 ||
            ele.nct_subject_id === 64 ||
            ele.nct_subject_id === 65 ||
            ele.nct_subject_id === 49 ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === 1
                ? "Male"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 2
                ? "Female"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ""
                ? "Not Available"
                : "Others"}
            </>
          ) : ele.nct_subject_id === 115 || ele.nct_subject_id === 116 ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === 1
                ? "Son"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 2
                ? "Father"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 3
                ? "Mother"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 4
                ? "Wife"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 5
                ? "Husband"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 6
                ? "Court Appointed"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 7
                ? "Daughter"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ""
                ? "Not Available"
                : "Others"}
            </>
          ) : ele.nct_subject_id === 152 || ele.nct_subject_id === 153 ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === 1
                ? "Farmer"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 2
                ? "Daily Wager"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === 3
                ? "Engineer"
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ""
                ? "Not Available"
                : "Others"}
            </>
          ) : Object.values(JSON.parse(ele.nct_old_value))[0] === "" ? (
            <>Not Available</>
          ) : (
            <>{Object.values(JSON.parse(ele.nct_old_value))[0]}</>
          );
        ele.nct_new_value =
          ele.nct_category_id === 1 ? null : ele.nct_subject_id === 154 ||
            ele.nct_subject_id === 155 ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === "1"
                ? "Yes"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === "2"
                ? "No"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ""
                ? "Not Available"
                : "Not Applicable"}
            </>
          ) : ele?.nct_subject_id === 1 || ele?.nct_subject_id === 2 ? (
            <div style={{ color: "black" }}>
              <span>RM Name:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[0]}
              <br />
              <span>Distributor Name:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[2]}
              <br />
              <span>Distributor ARN-Code:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[3]}
              <br />
              <span>Distributor Mobile Number:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[6]}
              <br />
              <span>Distributor Email Address: </span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[5]}
              <br />
              <span>Distributor EUIN No:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[4]}
            </div>
          ) : ele?.nct_subject_id === 127 || ele?.nct_subject_id === 128 ? (
            <div style={{ color: "black" }}>
              <span>Bank Name:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[0]}
              <br />
              <span>Bank Account Number:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[1]}
              <br />
              <span>Bank MICR Code:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[2]}
              <br />
              <span>Bank IFSC Code:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[3]}
              <br />
              <span>Bank Account Type:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[4]}
            </div>
          ) : ele?.nct_subject_id === 75 ||
            ele?.nct_subject_id === 76 ||
            ele?.nct_subject_id === 77 ||
            ele?.nct_subject_id === 78 ? (
            <div style={{ color: "black" }}>
              <span>Address Line 1:</span>:{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[0]}
              <br />
              <span>Address Line 2:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[1]}
              <br />
              <span>City:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[2]}
              <br />
              <span>State:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[3]
                ? Object.values(JSON.parse(ele.nct_new_value))[3]
                : "Not Available"}
              <br />
              <span>Pincode:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[4]}
              <br />
              <span>Country:</span>{" "}
              {Object.values(JSON.parse(ele.nct_new_value))[5]}
            </div>
          ) : ele.nct_subject_id === 28 ||
            ele.nct_subject_id === 48 ||
            ele.nct_subject_id === 64 ||
            ele.nct_subject_id === 65 ||
            ele.nct_subject_id === 49 ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === "1"
                ? "Male"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === "2"
                ? "Female"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ""
                ? "Not Available"
                : "Others"}
            </>
          ) : ele.nct_subject_id === 115 || ele.nct_subject_id === 116 ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === "1"
                ? "Son"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === "2"
                ? "Father"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === "3"
                ? "Mother"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === "4"
                ? "Wife"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === "5"
                ? "Husband"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === "6"
                ? "Court Appointed"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === "7"
                ? "Daughter"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ""
                ? "Not Available"
                : "Others"}
            </>
          ) : ele.nct_subject_id === 152 || ele.nct_subject_id === 153 ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === "1"
                ? "Farmer"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === "2"
                ? "Daily Wager"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === "3"
                ? "Engineer"
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ""
                ? "Not Available"
                : "Others"}
            </>
          ) : Object.values(JSON.parse(ele.nct_new_value))[0] === "" ? (
            <>Not Available</>
          ) : (
            <>{Object.values(JSON.parse(ele.nct_new_value))[0]}</>
          );
      });
    setNctTableData(nctData);
  }, [nctData]);

  const onFolioChange = (e) => {
    const selectedData = folioData.find((item) => item.account_number === e);
    e &&
      fetch(
        `${apiBaseUrl}/VNct/GetNCTbyAccountNumber/${e}/${selectedData.client_id}`
      )
        .then((response) => response.json())
        .then((json) => {
          setNctData(json.responseData);
          setDisableGetButton(false);
        })
        .catch((error) => console.log(error));
  };

  const fetchData = () => {
    setShowTable(true);
    setDisableGetButton(true);
  };

  const handleNCTChange = (_, filters, sorter) => {};
  const filterNCTData = (nctTableData) =>
    nctTableData.map((item) => ({
      key: item,
      value: item,
      text: item,
    }));
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search Here`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              setSelectedKeys([]);
              setSearchText("");
              setSearchedColumn("");
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90 }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <span className="ant-table-filter-icon-container">
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      </span>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const nctColumns = [
    {
      title: "S. No",
      dataIndex: "sno",
      align: "center",
      key: "1",
      responsive: ["md"],
    },
    {
      title: (
        <>
          Request
          <br />
          Registration
          <br />
          Date
        </>
      ),
      dataIndex: "nct_transaction_date",
      align: "center",
      key: "2",
      responsive: ["md"],
    },
    {
      title: "Unique Request No.",
      dataIndex: "nct_ih_number",
      align: "center",
      key: "3",
      responsive: ["md"],
      ...getColumnSearchProps("nct_ih_number"),
    },
    {
      title: (
        <>
          Service
          <br />
          Request
          <br />
          Category
        </>
      ),
      dataIndex: "nct_category_description",
      align: "center",
      responsive: ["md"],
      key: "4",
      filters: filterNCTData(
        nctTableData
          .map((item) => item.nct_category_description)

          .filter((value, index, self) => self.indexOf(value) === index)
      ),
      onFilter: (value, record) =>
        record.nct_category_description.indexOf(value) === 0,
    },
    {
      title: (
        <>
          Service
          <br />
          Request
        </>
      ),
      dataIndex: "nct_subject_description",
      align: "center",
      responsive: ["md"],
      key: "5",
      ...getColumnSearchProps("nct_subject_description"),
    },
    {
      title: "Value",
      dataIndex: "nct_new_value",
      render: (text, record) => {
        if (record.nct_old_value) {
          return (
            <>
              <span>
                Old Value: <span>{record.nct_old_value}</span>
              </span>
              <br />
              <span>
                New Value: <span>{text}</span>
              </span>
            </>
          );
        } else {
          return <span>{text}</span>;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "nct_status",
      align: "center",
      responsive: ["md"],
      key: "6",
      filters: filterNCTData(
        nctTableData
          .map((item) => item.nct_status)

          .filter((value, index, self) => self.indexOf(value) === index)
      ),
      onFilter: (value, record) => record.nct_status.indexOf(value) === 0,
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          margin: "0 auto",
          width: "70%",
          alignSelf: "center",
          marginTop: "3%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 40,
          }}
        >
          <Select
            showSearch
            placeholder="Select Folio Number"
            optionFilterProp="children"
            onChange={(e) => onFolioChange(e)}
            style={{
              fontSize: "14px",
              color: "#A3020C",
              width: "150px",
            }}
          >
            {folioData.map((ele, index) => (
              <Select.Option key={index} value={ele.account_number}>
                {ele.account_number}
              </Select.Option>
            ))}
          </Select>
          <Button
            disabled={disableGetButton}
            onClick={fetchData}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "140px",
              height: "33px",
              backgroundColor: "#A3020C",
              color: "white",
              borderRadius: 5,
            }}
          >
            Get
          </Button>
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              width: "140px",
              height: "33px",
              backgroundColor: "#A3020C",
              color: "white",
              borderRadius: 5,
            }}
            onClick={() => window.location.reload(false)}
          >
            Reset
          </Button>
        </div>
      </div>
      {showTable ? (
        <Table
          style={{
            display: "block",
            justifyContent: "center",
            marginTop: "3%",
          }}
          columns={nctColumns}
          dataSource={nctTableData}
          onChange={handleNCTChange}
          pagination={{
            pageSize: 10,
          }}
          bordered
        />
      ) : (
        <div
          className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
          role="alert"
        >
          Please Select Folio
        </div>
      )}
    </div>
  );
};

export default InteractionHistory;
