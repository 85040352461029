import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import { Table, Button } from "antd";
import { useStateContext } from "../../contexts/ContextProvider";
import moment from "moment";
import axios from "axios";
import "./Profile.css";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "center",
};

const flexColumnStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "center",
  gap: "2%",
};

const flexRowStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignContent: "center",
  gap: "2%",
};

const buttonStyle = {
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  width: "80px",
  height: "33px",
  backgroundColor: "#A3020C",
  color: "white",
  borderRadius: 5,
};

const DocumentDetails = (data) => {
  const ihNumbers = data?.data;
  const { apiBaseUrl } = useStateContext();
  const accountNumber = data?.folioNumber;
  const [loading, setLoading] = useState(false);
  const [documentTableData, setDocumentTableData] = useState([]);

  const getFilesByRefNumber = () => {
    let temp = [];
    ihNumbers.forEach((ele) => {
      axios
        .get(
          `${apiBaseUrl}/VTransaction/GetVaultDetails/${accountNumber}?transaction_ref_no=${ele}`
        )
        .then((response) => {
          if (response.data.responseData?.length > 0) {
            const updatedDocumentFiles = response.data.responseData?.map(
              (ele) => ({
                ...ele,
              })
            );
            temp.push(...updatedDocumentFiles);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => console.log(error));
    });
    setDocumentTableData(temp);
  };
  useEffect(() => {
    getFilesByRefNumber();
  }, [ihNumbers]);

  const openDocument = (s3Path) => {
    const modifiedPath = s3Path.replace("&export=download", "&export=view");
    window.open(modifiedPath, "_blank");
  };

  const documentColumn = [
    {
      title: "Reference Transaction Number",
      dataIndex: "entity_reference_number",
      width: 250,
    },
    {
      title: "Document Type",
      dataIndex: "document_type",
    },
    {
      title: "Creation Date",
      dataIndex: "creation_date",
      align: "center",
      render: (text, record) => {
        return <span>{moment(record.creation_date).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: "Documents",
      dataIndex: "s3_path",
      align: "center",
      width: 130,
      render: (text, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={buttonStyle}
              onClick={() => openDocument(record.s3_path)}
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h1 style={{ textAlign: "center", fontSize: "24px" }}>
        Investor Documents
      </h1>
      <div style={containerStyle}>
        <div style={flexColumnStyle}>
          <div style={flexRowStyle}>
            <p>Investor Name: {data?.investorName}</p>
            <p>Investment Class: {data?.classCategory}</p>
          </div>
          <div style={flexRowStyle}>
            <p>Client Name: {data?.clientName}</p>
            <p>Fund Name: {data?.fundName}</p>
          </div>
        </div>
      </div>

      {loading ? (
        <p style={{ textAlign: "center", fontSize: "20px" }}>Loading...</p>
      ) : (
        <>
          {documentTableData?.length === 0 ? (
            <p style={{ textAlign: "center", fontSize: "20px" }}>
              No Documents Available
            </p>
          ) : (
            <Table
              bordered
              columns={documentColumn}
              dataSource={documentTableData}
              pagination={{
                pageSize: 10,
              }}
              size="small"
              fontSize="10px"
              style={{
                marginTop: "2%",
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DocumentDetails;
