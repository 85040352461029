import React, { useState } from "react";
import { Select, Button, Table } from "antd";
import { useStateContext } from "../contexts/ContextProvider";
import axios from "axios";
import moment from "moment";
import LoadingScreen from "react-loading-screen";
const containerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "center",
};

const flexColumnStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "center",
  gap: "2%",
};

const flexRowStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignContent: "center",
  gap: "2%",
};

const buttonStyle = {
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  width: "80px",
  height: "33px",
  backgroundColor: "#A3020C",
  color: "white",
  borderRadius: 5,
};
const DocDetails = () => {
  const { apiBaseUrl, folioData, setIsCrashed } = useStateContext();
  const [documentTableData, setDocumentTableData] = useState([]);
  const [disableGetButton, setDisableGetButton] = useState(true);
  const [folioNumber, setFolioNumber] = useState("");
  const onChangeFolio = (value) => {
    setDisableGetButton(false);
    const selectedData = folioData.find(
      (item) => item.account_number === value
    );
    setFolioNumber(selectedData.account_number);
  };
  const [profData, setProfData] = useState([]);
  const [showProfile, setShowProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    setLoading(true);
    setShowProfile(true);
    setDisableGetButton(false);
    try {
      const resp = await axios.get(
        `${apiBaseUrl}/VAccount/Getvadiledaccount/${folioNumber}`
      );
      const profileResData = await resp.data?.responseData;
      if (resp.data.code === -100) {
        setIsCrashed(true);
        return; // Return early to stop further processing
      }
      const clientId = resp.data?.responseData[0]?.client_id;
      const [transactionResponse, nctResponse] = await Promise.all([
        axios.get(
          `${apiBaseUrl}/VTransaction/GetTransactionsCommon/${clientId}?accountnumber=${folioNumber}&rejecttxn=true`
        ),
        axios.get(
          `${apiBaseUrl}/VNct/GetNCTbyAccountNumber/${folioNumber}/${clientId}`
        ),
      ]);
      const nctTrxnData = await nctResponse.data.responseData;
      const transactionsData = await transactionResponse.data.responseData;
      const transactionIhnumbers = await transactionsData
        .map((ele) => [ele.ih_number])
        .flat();
      const nctIhNumbers = await nctTrxnData
        .map((ele) => [ele.nct_ih_number])
        .flat();
      const ihNumbers = [...transactionIhnumbers, ...nctIhNumbers];
      setProfData(profileResData);
      getFilesByRefNumber(ihNumbers);
    } catch (error) {
      setLoading(false);
      alert("something went wrong");
    }
  };

  const getFilesByRefNumber = async (ihNumbers) => {
    try {
      const promises = ihNumbers.map(async (ele) => {
        const response = await axios.get(
          `${apiBaseUrl}/VTransaction/GetVaultDetails/${folioNumber}?transaction_ref_no=${ele}`
        );
        if (response.data.responseData?.length > 0) {
          const updatedDocumentFiles = response.data.responseData.map(
            (ele) => ({
              ...ele,
            })
          );
          return updatedDocumentFiles;
        } else {
          return [];
        }
      });

      const documentDataArray = await Promise.all(promises);
      const flattenedDataArray = documentDataArray.flat(); // Flatten the array
      setDocumentTableData(
        flattenedDataArray.filter((ele) => ele.s3_path !== "")
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const openDocument = (s3Path) => {
    const modifiedPath = s3Path.replace("&export=download", "&export=view");
    window.open(modifiedPath, "_blank");
  };

  const documentColumn = [
    {
      title: "Reference Transaction Number",
      dataIndex: "entity_reference_number",
      width: 250,
    },
    {
      title: "Document Type",
      dataIndex: "document_type",
    },
    {
      title: "Creation Date",
      dataIndex: "creation_date",
      align: "center",
      render: (text, record) => {
        return <span>{moment(record.creation_date).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: "Documents",
      dataIndex: "s3_path",
      align: "center",
      width: 130,
      render: (text, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={buttonStyle}
              onClick={() => openDocument(record.s3_path)}
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          fontSize: "30px",
          color: "#680F10",
        }}
      >
        Fund Documents
      </h1>
      <div style={{ margin: "0 auto", width: "70%", alignSelf: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 70,
          }}
        >
          <Select
            showSearch
            style={{
              fontSize: "14px",
              color: "#680F10",
              width: "200px",
            }}
            placeholder="Select the Folio"
            optionFilterProp="children"
            onChange={(e) => onChangeFolio(e)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {folioData?.map((item) => (
              <Select.Option value={item.account_number}>
                {item.account_number}
              </Select.Option>
            ))}
          </Select>
          <Button
            disabled={disableGetButton}
            onClick={fetchData}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "140px",
              height: "33px",
              backgroundColor: "#680F10",
              color: "white",
              borderRadius: 5,
            }}
          >
            Get
          </Button>
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              width: "140px",
              height: "33px",
              backgroundColor: "#680F10",
              color: "white",
              borderRadius: 5,
            }}
            onClick={() => window.location.reload(false)}
          >
            Reset
          </Button>
        </div>
      </div>

      {showProfile ? (
        <>
          {loading ? (
            <LoadingScreen
              loading={true}
              bgColor="transparent"
              spinnerColor="#000"
              textColor="#000"
              text="Loading..."
            >
              <h1>Loading...</h1>
            </LoadingScreen>
          ) : (
            <div
              style={{
                width: "94%",
                margin: "0 auto",
                marginTop: "1%",
              }}
            >
              <div className={"content  active-content"}>
                <div>
                  <h1 style={{ textAlign: "center", fontSize: "24px" }}>
                    Investor Documents
                  </h1>
                  <div style={containerStyle}>
                    <div style={flexColumnStyle}>
                      <div style={flexRowStyle}>
                        <p>
                          Investor Name:
                          {"  "}
                          {profData[0]?.investor_name}
                        </p>
                        <p>Investment Class: {profData[0]?.class_name}</p>
                      </div>
                      <div style={flexRowStyle}>
                        <p>Client Name: {profData[0]?.client_name}</p>
                        <p>Fund Name: {profData[0]?.fund_name}</p>
                      </div>
                    </div>
                  </div>

                  {documentTableData?.length === 0 ? (
                    <p style={{ textAlign: "center", fontSize: "20px" }}>
                      No Documents Available
                    </p>
                  ) : (
                    <Table
                      bordered
                      columns={documentColumn}
                      dataSource={documentTableData}
                      pagination={{
                        pageSize: 10,
                      }}
                      size="small"
                      fontSize="10px"
                      style={{
                        marginTop: "2%",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
          role="alert"
        >
          Please Select Folio Number
        </div>
      )}
    </div>
  );
};

export default DocDetails;
