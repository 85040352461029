import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Dashboard, ServiceRequests, Transactions } from "..";
import ForgotPassword from "./LoginManagement/ForgotPassword";
import ChangePassword from "./LoginManagement/ChangePassword";
import Profile from "../Profile/Profile";
import ServiceRequest from "../QuickServices/ServiceRequest/ServiceRequest";
import { useStateContext } from "../../contexts/ContextProvider";
import StatementMailBack from "../QuickServices/StatementMailBack";
import TransactionStatus from "../QuickServices/TransactionStatus";
import DrawdownCalendar from "../QuickServices/DrawdownCalendar";
import NavHistory from "../QuickServices/NavHistory";
import DocDetails from "../DocDetails";
// import DrawDown from './pages/DrawDown';
const Application = () => {
  const { accountNumber, loggedIn } = useStateContext();

  return (
    <div style={{ overflowX: "hidden", height: "fit-content" }}>
      <Routes>
        {/* Dashboard or Home*/}
        <Route
          path="/Login"
          element={
            loggedIn ? (
              <Navigate to={`/Dashboard`} />
            ) : (
              <Navigate to={`/Login`} />
            )
          }
        />

        <Route path="/ForgotPassword" element={<ForgotPassword />} />

        <Route path="/ChangePassword" element={<ChangePassword />} />

        <Route
          path="/Dashboard"
          // element={<Home />}
          element={loggedIn ? <Dashboard /> : <Navigate to={`/Login`} />}
        />

        <Route
          path="/ServiceRequests"
          element={loggedIn ? <ServiceRequests /> : <Navigate to={`/Login`} />}
        />
        <Route path="/ServiceRequest" element={<ServiceRequest />} />
        <Route
          path="/FundDocuments"
          element={loggedIn ? <DocDetails /> : <Navigate to={`/Login`} />}
        />
        <Route path="/StatementMailBack" element={<StatementMailBack />} />
        <Route path="/NavHistory" element={<NavHistory />} />
        <Route path="/TransactionStatus" element={<TransactionStatus />} />
        <Route path="/DrawdownCalendar" element={<DrawdownCalendar />} />

        <Route
          path="/Profile"
          element={
            loggedIn ? (
              <Profile data={accountNumber} />
            ) : (
              <Navigate replace to={`/Login`} />
            )
          }
        />
        <Route
          path="/Transactions"
          element={
            loggedIn ? (
              <Transactions data={accountNumber} />
            ) : (
              <Navigate to={`/Login`} />
            )
          }
        />
      </Routes>
    </div>
  );
};

export default Application;
