import React from "react";
const SecondHolderDetails = (props) => {
  const { secondHolderData } = props;
  const InfoItem = ({ label, value }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-betwen",
      }}
    >
      <p style={{ width: "35%", textAlign: "left" }}>{label}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "65%",
        }}
      >
        <div>:</div>
        <div style={{ marginLeft: "3%", textAlign: "left" }}>
          <b>{value}</b>
        </div>
      </div>
    </div>
  );
  const DateInfoItem = ({ label, date }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-betwen",
      }}
    >
      <p style={{ width: "35%", textAlign: "left" }}>{label}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "65%",
        }}
      >
        <div>:</div>
        <div style={{ marginLeft: "3%" }}>
          <b>
            {" "}
            {date
              ? new Date(date).toLocaleDateString("en-GB")
              : "Not Available"}
          </b>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/* Joint Holder Details */}
      {secondHolderData?.length > 0 ? (
        <>
          <div style={{ fontWeight: "bold", textAlign: "left" }}>
            <h2 style={{ fontSize: "24px", textAlign: "center" }}>
              <u>Joint Holder Details</u>
            </h2>
            First Joint Holder
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1%",
                width: "50%",
              }}
            >
              <InfoItem
                label="Name"
                value={`${secondHolderData[0]?.account_Holder?.first_name} `}
              />
              <InfoItem
                label="Gender"
                value={
                  secondHolderData[0]?.account_Holder?.gender_id === 1
                    ? "Male"
                    : secondHolderData[0]?.account_Holder?.gender_id === 2
                    ? "Female"
                    : "Others"
                }
              />
              <InfoItem
                label="Relation"
                value={
                  secondHolderData[0]?.holder_relationship_type_id === 1
                    ? "Son"
                    : secondHolderData[0]?.holder_relationship_type_id === 2
                    ? "Father"
                    : secondHolderData[0]?.holder_relationship_type_id === 3
                    ? "Mother"
                    : secondHolderData[0]?.holder_relationship_type_id === 4
                    ? "Wife"
                    : secondHolderData[0]?.holder_relationship_type_id === 5
                    ? "Husband"
                    : secondHolderData[0]?.holder_relationship_type_id === 6
                    ? "Court Appointed"
                    : secondHolderData[0]?.holder_relationship_type_id === 7
                    ? "Daughter"
                    : secondHolderData[0]?.holder_relationship_type_id === null
                    ? "Not Available"
                    : "Others"
                }
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "2%",
                width: "50%",
              }}
            >
              <DateInfoItem
                label="Date of birth"
                date={secondHolderData[0]?.account_Holder?.dob}
              />

              <InfoItem
                label="PAN Number"
                value={
                  secondHolderData[0]?.prooflist &&
                  secondHolderData[0]?.prooflist[0]?.proof_value ? (
                    <span>
                      {secondHolderData[0]?.prooflist[0]?.proof_value}
                    </span>
                  ) : (
                    <span>Not Available</span>
                  )
                }
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SecondHolderDetails;
