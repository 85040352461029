import React from "react";

const BankDetails = (data) => {
  const bankData = data?.bankData;
  const dematData = data?.data[0]?.account_Holder;
  const bankIFSCData = data?.ifscData;
  const bankAddress = `${bankIFSCData?.address}, ${bankIFSCData?.city1}, ${bankIFSCData?.state_name}`;

  const InfoItem = ({ label, value }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-betwen",
      }}
    >
      <p style={{ width: "35%", textAlign: "left" }}>{label}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "65%",
        }}
      >
        <div>:</div>
        <div
          style={{
            marginLeft: "3%",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          {value}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <h2 style={{ fontSize: "24px", textAlign: "center" }}>
        <u>Bank Details</u>
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "4%",
            width: "50%",
          }}
        >
          <InfoItem
            label="Bank Name"
            value={`${
              bankData?.accountbankdata?.bank_name
                ? bankData?.accountbankdata?.bank_name
                : "Not Available"
            }`}
          />
          <InfoItem
            label="Account Number"
            value={`${
              bankData?.accountbankdata?.bank_account_number
                ? bankData?.accountbankdata?.bank_account_number
                : "Not Available"
            }`}
          />
          <InfoItem
            label="Account Type"
            value={`${
              bankData?.accountbankdata?.account_type
                ? bankData?.accountbankdata?.account_type
                : "Not Available"
            }`}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "4%",
            width: "50%",
          }}
        >
          <InfoItem
            label="IFSC Code"
            value={`${
              bankData?.accountbankdata?.bank_ifsc
                ? bankData?.accountbankdata?.bank_ifsc
                : "Not Available"
            }`}
          />
          <InfoItem
            label="MICR Code"
            value={`${
              bankData?.accountbankdata?.bank_micr
                ? bankData?.accountbankdata?.bank_micr
                : "Not Available"
            }`}
          />
          <InfoItem
            label="Address"
            value={`${
              bankData?.accountbankdata?.bank_ifsc !== null ||
              bankIFSCData !== null
                ? bankAddress
                : "Not Available"
            }`}
          />
        </div>
      </div>
      {dematData?.demat_flag === "Y" ? (
        <>
          <h2>
            <u>Demat Details</u>
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "4%",
                width: "50%",
              }}
            >
              <InfoItem
                label="Demat Participant Name"
                value={dematData?.depository_participant_name}
              />
              <InfoItem
                label="Client ID Number"
                value={dematData?.client_id_num}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "4%",
                width: "50%",
              }}
            >
              <InfoItem label="DP ID" value={dematData?.dp_id} />
              <InfoItem
                label="Beneficiary Account Number"
                value={dematData?.benificiary_account_num}
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default BankDetails;
