import React, { useEffect, useRef } from "react";
import "./App.css";
import "antd/dist/antd.css";
import { Modal } from "antd";
import { SideBar, Navbar } from "./components";
import { Routes, Route, Navigate } from "react-router-dom";
import AppRoutes from "./pages/auth/Approutes";
import Login from "./pages/auth/LoginManagement/Login";
import { useStateContext } from "./contexts/ContextProvider";
import ForgotPassword from "./pages/auth/LoginManagement/ForgotPassword";
import ChangePassword from "./pages/auth/LoginManagement/ChangePassword";
import ServiceRequest from "./pages/QuickServices/ServiceRequest/ServiceRequest";
import StatementMailBack from "./pages/QuickServices/StatementMailBack";
import NavHistory from "./pages/QuickServices/NavHistory";
import TransactionStatus from "./pages/QuickServices/TransactionStatus";
import DrawdownCalendar from "./pages/QuickServices/DrawdownCalendar";
import ChatQuery from "./pages/ChatQuery";
const App = () => {
  const { loggedIn, handleLogOut } = useStateContext();
  const { activeMenu } = useStateContext();

  const timeoutRef = useRef(null);
  // Attach resetTimer to user actions
  useEffect(() => {
    const resetTimer = () => {
      if (loggedIn) {
        // Reset timer when user performs an action
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          handleLogOut();
          Modal.warning({
            title: "You have been logged out due to inactivity.",
            content: "Please log in again.",
            onOk: () => {
              handleLogOut();
            },
          });
        }, 30 * 60 * 1000);
      }
    };

    window.addEventListener("click", resetTimer);
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    return () => {
      window.removeEventListener("click", resetTimer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      clearTimeout(timeoutRef.current);
    };
  }, [loggedIn, handleLogOut]);

  return (
    <div>
      {/*  This div is the entire Webpage */}
      {loggedIn ? (
        <>
          <div className="flex relative dark:bg-main-dark-bg">
            <Routes>
              <Route
                path="/"
                element={
                  !loggedIn ? <Login /> : <Navigate replace to={"/Dashboard"} />
                }
              />
            </Routes>

            {/* Side Bar Component */}

            {activeMenu ? (
              <div
                className="w-72 fixed sidebar
                    dark:bg-secodary-dark-bg
                    bg-white"
              >
                <SideBar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secodary-dark-bg">
                <SideBar />
              </div>
            )}

            {/* Navbar Component */}
            <div
              className={`max-h-max dark:bg-main-bg min-h-screen bg-main-bg w-full ${
                activeMenu ? "md:ml-72" : "flex-2"
              }`}
            >
              <div
                className="fixed md:static bg-main-bg dark:bg-main-bg navbar w-full"
                style={{ backgroundColor: "#F1F6F9" }}
              >
                <Navbar />
              </div>
              <div className="h-full" style={{ backgroundColor: "#F1F6F9" }}>
                <div>
                  <AppRoutes />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/Analytics" element={<ChatQuery />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route path="/ServiceRequest" element={<ServiceRequest />} />
            <Route path="/StatementMailBack" element={<StatementMailBack />} />
            <Route path="/NavHistory" element={<NavHistory />} />
            <Route path="/TransactionStatus" element={<TransactionStatus />} />
            <Route path="/DrawdownCalendar" element={<DrawdownCalendar />} />
            <Route
              path="/"
              element={!loggedIn ? <Login /> : <Navigate replace to="/" />}
            />

            <Route
              path="/Login"
              element={!loggedIn ? <Login /> : <Navigate replace to="/" />}
            />
            <Route
              path="*"
              element={!loggedIn ? <Login /> : <Navigate replace to="/" />}
            />
          </Routes>
        </>
      )}
    </div>
  );
};

export default App;
