import React, { useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";
// import uparrow from '../assets/up-arrow.png'
import { Spin } from "antd";
import axios from "axios";
// import downarrow from '../assets/down-arrow.png'
import moment from "moment";
import Links from "../components/Links";
// import { Chart } from "react-google-charts";

const Dashboard = () => {
  const { formatNumberWithCommas, folioData, apiBaseUrl } = useStateContext();
  const [selectedAccountNumber, setSelectedAccountNumber] = React.useState(
    folioData[0]?.account_number
  );
  const [loading, setLoading] = React.useState(true);
  // const pieData =[  ["Fund", "Amount"], [fund, parseFloat(value)]];
  const [investorData, setInvestorData] = React.useState([]);
  // const options = {
  //   title: "Funds",
  //   pieHole: 0.4,
  //   is3D: true,
  // };

  const handleAccountNumberClick = async (accountNumber) => {
    setSelectedAccountNumber(accountNumber);
    const selectedData = folioData.find(
      (item) => item.account_number === accountNumber
    );
    axios
      .get(
        `${apiBaseUrl}/VReports/AccountStatementDetails/${selectedData.client_id}/${selectedData.fund_id}/${accountNumber}`
      )
      .then((response) => {
        setInvestorData(
          response.data.responseData?.accountStatementReportModel
        );
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    handleAccountNumberClick(selectedAccountNumber);
  }, []);

  return (
    <>
      <div style={{ fontWeight: "bold", marginLeft: "3%", color: "#000" }}>
        Your Folio Number{folioData.length > 1 && "s"}:
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          marginLeft: "3%",
        }}
      >
        {folioData.map((item, index) => (
          <a
            key={index}
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "2px 5px",
              marginRight: "5px",
              marginBottom: "5px",
              maxWidth: "10%",
              cursor: "pointer",
              color: "#680F10",
            }}
            onClick={() => handleAccountNumberClick(item.account_number)}
          >
            {item.account_number}
          </a>
        ))}
      </div>
      <div
        style={{
          backgroundColor: "#FFF",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.20)",
          width: "95%",
          borderRadius: 10,
          padding: "2%",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingRight: "2%",
            paddingLeft: "2%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontWeight: "bold", marginTop: "4%" }}>
            Your Investment
            <br />
            Summary for
            <br />
            {selectedAccountNumber}
            <br />
            {investorData?.fund_description
              ? investorData?.fund_description
              : "Not Available"}
          </div>
          <div>
            {investorData?.as_on_date ? (
              <div>
                Your Investment Value <br /> as on{" "}
                {investorData?.as_on_date
                  ? moment(investorData?.as_on_date).format("DD. MM. YYYY")
                  : ""}
              </div>
            ) : null}

            <div
              style={{ marginTop: "20%", fontWeight: "bold", fontSize: "20px" }}
            >
              {/* INR {formatNumberWithCommas(investorData?.market_value)} */}
              {loading ? (
                <Spin tip="Loading" size="large" style={{ marginTop: "10%" }}>
                  <div className="content" />
                </Spin>
              ) : (
                <>
                  {investorData?.absolute_returns ? "INR  " : null}
                  {investorData?.market_value !== null ||
                  investorData?.market_value !== ""
                    ? formatNumberWithCommas(investorData?.market_value)
                    : "Not Available"}
                </>
              )}
            </div>
            <div>
              {investorData?.nav_as_on ? (
                <div
                  style={{
                    // marginTop: "20%",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Current NAV :{" "}
                  {investorData?.nav_as_on
                    ? formatNumberWithCommas(investorData?.nav_as_on)
                    : "Not Available"}{" "}
                </div>
              ) : null}
            </div>
          </div>

          <div style={{ backgroundColor: "blue", position: "relative" }}>
            {/* {loading && (
              <Spin
                tip="Loading Funds"
                size="large"
                style={{
                  marginTop: '30%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <div className="content" />
              </Spin>
            )} */}
            {/* <Chart
              chartType="PieChart"
              data={pieData}
              options={options}
              width="100%"
              height="300px"
              loader={<div>Loading Chart...</div>}
              events={[
                {
                  eventName: 'ready',
                  callback: () => loading(false),
                },
              ]}
            /> */}
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#F1F6F9",
            padding: "2%",
            marginTop: "1%",
            borderRadius: 10,
          }}
        >
          <span style={{ fontWeight: "bold", fontSize: "18px" }}>
            Your Investment Summary
          </span>
          <span style={{ marginLeft: "1%", fontSize: "12px" }}>
            (As on{" "}
            {investorData?.as_on_date
              ? moment(investorData?.as_on_date).format("DD. MM. YYYY")
              : "Not Available"}
            )
          </span>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "3%",
              justifyContent: "space-around",
            }}
          >
            <div style={{ textAlign: "center" }}>
              Total Investment
              <div
                style={{
                  textAlign: "center",
                  marginTop: "15%",
                  fontWeight: "bold",
                }}
              >
                {loading ? (
                  <Spin size="small">
                    <div className="content" />
                  </Spin>
                ) : (
                  <>
                    {investorData?.absolute_returns ? "INR " : null}{" "}
                    {investorData?.capital_paid
                      ? formatNumberWithCommas(investorData?.capital_paid)
                      : "Not Available"}
                  </>
                )}
                {/* INR {formatNumberWithCommas(investorData?.capital_paid)} */}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              Current Value
              <div
                style={{
                  textAlign: "center",
                  marginTop: "15%",
                  fontWeight: "bold",
                }}
              >
                {/* INR {formatNumberWithCommas(investorData?.market_value)} */}
                {loading ? (
                  <Spin size="small">
                    <div className="content" />
                  </Spin>
                ) : (
                  <>
                    {investorData?.absolute_returns ? "INR " : null}{" "}
                    {investorData?.market_value
                      ? formatNumberWithCommas(investorData?.market_value)
                      : "Not Available"}
                  </>
                )}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              Absolute Returns
              <div
                style={{
                  textAlign: "center",
                  marginTop: "15%",
                  fontWeight: "bold",
                }}
              >
                {loading ? (
                  <Spin size="small">
                    <div className="content" />
                  </Spin>
                ) : (
                  <>
                    {investorData?.absolute_returns ? "INR " : null}
                    {investorData?.absolute_returns
                      ? formatNumberWithCommas(investorData?.absolute_returns)
                      : "Not Available"}
                  </>
                )}
                {/* INR {formatNumberWithCommas(investorData?.absolute_returns)} */}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              IRR
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20%",
                  fontWeight: "bold",
                }}
              >
                {loading ? (
                  <Spin size="small">
                    <div className="content" />
                  </Spin>
                ) : (
                  <>
                    {investorData?.xirr ? (
                      <div> {investorData?.xirr} %</div>
                    ) : (
                      "Not Available"
                    )}
                  </>
                )}
                {/* {investorData.xirr} % */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          marginLeft: "4%",
          alignSelf: "center",
          width: "100%",
          marginTop: "2%",
          marginBottom: "4%",
        }}
      >
        <Links />
      </div>
    </>
  );
};

export default Dashboard;
