import React, { useEffect, useState } from "react";
import { Select, Button, DatePicker, Table, Modal } from "antd";
import { useStateContext } from "../../contexts/ContextProvider";
import moment from "moment";

const NavHistory = () => {
  const { apiBaseUrl } = useStateContext();
  const [fundHouseData, setFundHouseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onChangeFromDate, setOnChangeFromDate] = useState(true);
  const [toDate, setToDate] = useState("");
  const [disableToDate, setDisableToDate] = useState(true);
  const [schemeData, setSchemeData] = useState([]);
  const [selectedFundHouse, setSelectedFundHouse] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [selectedScheme, setSelectedScheme] = useState("");
  const [disableScheme, setDisableScheme] = useState(true);

  const [showTable, setShowTable] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [transTableData, setTransTableData] = useState([{}]);

  // const items = [
  //   {
  //     key: "1",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.antgroup.com"
  //       >
  //         Excel
  //       </a>
  //     ),
  //     icon: <RiFileExcel2Fill />,
  //   },
  //   {
  //     key: "2",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.aliyun.com"
  //       >
  //         PDF
  //       </a>
  //     ),
  //     icon: <AiFillFilePdf />,
  //   },
  //   // {
  //   //   key: '3',
  //   //   label: (
  //   //     <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
  //   //       Screen
  //   //     </a>
  //   //   ),
  //   //   icon: <SlScreenDesktop />,
  //   // },
  //   {
  //     key: "3",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.luohanacademy.com"
  //       >
  //         Mail
  //       </a>
  //     ),
  //     icon: <CgMail />,
  //   },
  // ];

  const onChange = (e) => {
    setFromDate(moment(e).format("YYYY-MM-DD"));
    setDisableToDate(false);
  };
  const onChangeToDate = (e) => {
    setToDate(moment(e).format("YYYY-MM-DD"));
  };

  const fetchFundHouseData = async () => {
    fetch(`${apiBaseUrl}/VClient/GetClients`)
      .then((response) => response.json())
      .then((json) => setFundHouseData(json.responseData))
      .catch((error) => alert(`Error Loading Data : ${error.message}`));
  };

  const handleSelectFundHouse = (e) => {
    setSelectedFundHouse(e);
    setDisableScheme(false);
    fetch(`${apiBaseUrl}/VFund/GetFundbyCilentid/${e}`)
      .then((response) => response.json())
      .then((json) => {
        setSchemeData(json.responseData);
      })
      .catch((error) => console.log(`Error Loading Data : ${error.message}`));
  };

  useEffect(() => {
    fetchFundHouseData();
  }, []);

  const handleSelectScheme = (e) => {
    setSelectedScheme(e);
    setLoading(true);
    setOnChangeFromDate(false);
  };

  const showTableData = () => {
    Modal.info({
      title: "Transaction Status",
      content: <div>No Transaction Found</div>,
      onOk() {},
    });

    setShowTable(true);
  };

  const submit = () => {
    fetch(
      `${apiBaseUrl}/VFund/GetNavHistrory?clientid=${selectedFundHouse}&fundid=${selectedScheme}&from_date=${fromDate}&todate=${toDate}`
    )
      .then((response) => response.json())
      .then((json) => {
        setTableData(json.responseData);
        json.responseData?.length !== 0 ? setShowTable(false) : showTableData();
      })
      .catch((error) => console.log(`Error Loading Data : ${error.message}`));
  };

  useEffect(() => {
    tableData.length !== 0 &&
      tableData.forEach((ele, index) => {
        ele.sno = index + 1;
        ele.start_date = moment(ele.start_date).format("DD/MM/YYYY").toString();
        ele.end_date = moment(ele.end_date).format("DD/MM/YYYY").toString();
        ele.transaction_status =
          ele.transaction_status === "P" || ele.transaction_status === "p"
            ? "Pending"
            : ele.transaction_status === "Y" || ele.transaction_status === "y"
            ? "Success"
            : "Null";
      });
    setTransTableData(tableData);
  }, [tableData]);

  const columns = [
    {
      title: "S. No",
      dataIndex: "sno",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
    },
    {
      title: "Fund",
      dataIndex: "client_name",
    },
    {
      title: "Scheme",
      dataIndex: "fund_name",
    },
    {
      title: "NAV",
      dataIndex: "nav",
    },
  ];

  const list = [
    { description: "Select the fund /scheme", key: 0 },
    { description: "Choose the period", key: 1 },
    {
      description:
        "Select how you wish to see the report.  Can you view the report on screen or download them in various formats.",
      key: 2,
    },
    {
      description:
        "Do check the spam folder once you have requested for a mail back and you have not received the mail in 15 minutes",
      key: 3,
    },
    { description: "Please add our mail id to safe senders list", key: 4 },
  ];

  return (
    <>
      <div
        style={{
          backgroundColor: "black",
          padding: 20,
          justifyContent: "center",
        }}
        className="background-1"
      >
        <h2 style={{ color: "white", fontSize: 15 }}>Investor Services</h2>
        <h2 style={{ color: "white", fontSize: 26, fontWeight: "bold" }}>
          Nav History
        </h2>
      </div>
      {showTable ? (
        <div style={{ height: "80vh", display: "flex", flexDirection: "row" }}>
          <div style={{ backgroundColor: "#FFF", width: "55%", padding: "7%" }}>
            <div style={{ fontSize: "18px" }}>
              You can view the NAVs of all funds/ schemes serviced by us
            </div>
            <div style={{ marginTop: "5%" }}>
              <ol>
                {list.map((question) => {
                  return (
                    <div style={{ marginTop: "3%", fontSize: "16px" }}>
                      <li key={question.key}>
                        {question.key + 1} .
                        <span style={{ marginLeft: "5%" }}>
                          {question.description}
                        </span>
                      </li>
                    </div>
                  );
                })}
              </ol>
            </div>
            <div className="text-center mt-10">
              Write to us at
              <span className="ml-2">
                <a
                  href="mailto:investorcare@validusfintech.com"
                  style={{ color: "#000", textDecoration: "none" }}
                >
                  investorcare@validusfintech.com
                </a>
              </span>
            </div>
          </div>
          <div style={{ backgroundColor: "#F1F6F9", width: "40%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5%",
              }}
            >
              <div style={{ marginTop: "2%" }}>
                <div>Fund</div>
                <Select
                  showSearch
                  placeholder="Select Fund House"
                  optionFilterProp="children"
                  onChange={(e) => handleSelectFundHouse(e)}
                  style={{
                    fontSize: "14px",
                    color: "#A3020C",
                    width: "450px",
                  }}
                >
                  {fundHouseData?.length !== 0
                    ? fundHouseData.map((ele, index) => (
                        <Select.Option key={index} value={ele.client_id}>
                          {ele.client_name}
                        </Select.Option>
                      ))
                    : null}
                </Select>
              </div>
              <div style={{ marginTop: "2%" }}>
                <div>Scheme</div>
                <Select
                  disabled={disableScheme}
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={(e) => handleSelectScheme(e)}
                  style={{
                    fontSize: "14px",
                    color: "#A3020C",
                    width: "450px",
                  }}
                >
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div
                style={{
                  marginTop: "2%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "82%",
                }}
              >
                <div>
                  <div>From Date</div>
                  <DatePicker
                    placeholder="Select From Date"
                    format="DD/MM/YYYY"
                    disabled={onChangeFromDate}
                    selected={fromDate}
                    disabledDate={(current) => {
                      return current >= moment().endOf("day");
                    }}
                    onChange={onChange}
                  />
                </div>
                <div>
                  <div>To Date</div>
                  <DatePicker
                    disabled={disableToDate}
                    placeholder="Select To Date"
                    format="DD/MM/YYYY"
                    selected={toDate}
                    disabledDate={(current) => {
                      const isBeforeFrom =
                        current < moment(fromDate).startOf("day");
                      const isAfterNow = current > moment().endOf("day");

                      return isBeforeFrom || isAfterNow;
                    }}
                    onChange={onChangeToDate}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5%",
                  marginLeft: "2%",
                }}
              >
                <Button
                  onClick={submit}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    width: "180px",
                    height: "33px",
                    backgroundColor: "maroon",
                    color: "white",
                    borderRadius: 5,
                  }}
                >
                  View
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              marginTop: "1%",
              fontSize: "30px",
              color: "#A3020C",
            }}
          >
            NAV Details
          </h1>
          {/* <div style={{textAlign:'right', marginRight: '5%'}}>
          <Dropdown
            menu={{
              items,
            }}
            placement="bottom"
            arrow
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Options
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
          </div> */}
          <div style={{ width: "90%", margin: "0 auto" }}>
            <Table
              style={{
                display: "block",
                justifyContent: "center",
                marginTop: "3%",
              }}
              columns={columns}
              dataSource={transTableData}
              pagination={{
                pageSize: 10,
              }}
              bordered
            />
          </div>
          {/* <div className='flex justify-center content-center mt-10 mb-10'>
            <NAVHistoryDetails 
            data={transTableData} 
            fromDate={fromDate}
            toDate={toDate}/>
          </div> */}
        </>
      )}
    </>
  );
};

export default NavHistory;
