import React, { useEffect, useState } from "react";
import * as jose from "jose";
import { v4 as uuidv4 } from "uuid";

const ChatQuery = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState("");

    useEffect(() => {
        genreateJwtToken();
    }, []);

    const genreateJwtToken = async () => {
        setIsLoading(true);
        // Generate JWT
        const payload = {
            tenantId: "204b5d0d-6ed3-4a4b-b365-da2584918278",
            jti: uuidv4(), // You can use a library like 'uuid' for generating UUIDs
            sub: uuidv4(),
            email: "digital@validusfintech.com",
            firstName: "Validus",
            lastName: "Digital",
            language: "en-US",
        };

        // Replace 'your-private-key-here' with your private key in PEM format
        const private_key = `-----BEGIN PRIVATE KEY-----
	MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDtl7nBbcBueTc5
	KN52vZgGjBq+WUR6cj/p5oIFCSKcRw7bsHEKcCMPM6Zjr5rDm/R9odXwBtv/17OE
	xtBUsf3OXMBhdLoyIW2SuTqHulpHHD2nJQeJKYfXxNmDlq149b8fwAicSs4XlY3R
	mcV1ncECgfC7SAmn62SOt0m/OkgCwGU4OyJupI0qfCbXP1IL/vRIKBoSPP6LxSas
	/j9ZzmIA1lNClfmeyFOEIB6TkHOkKrF1xvqbeoeu6V8tnucz30BTdSsZYtzGKc/s
	Sw2M9I++CjKpzjasqD8KMTrcPm9KV92nrzOAlxM6GSn7m3K9etn5Z0eQZFFJhgzq
	dqjm99bTAgMBAAECggEATxycT5n5308hYjkHikYrHtL7/LHAqXztZzbHyGF4Us0e
	V2xy12TrZ55tIvhP8h411LdUgy3lh1UNg4Knz+vMdTBRJqI1dYfH8nkW0ibs9Iyk
	wskDr1pSzzfOrZA4hvRdBXdt5ozJ/vRfWpF3PGig4fLec53TCUTKIPNK7Q+89pzx
	SxfU57Qhh3U5zCexvkiXdXubxob2DE5JyaiCRoTgsvtTrFEkQxE0eY3+Wej5U++1
	LCrX95nWZ4OeS/r17V/MgbTp0t5snhdjVFXVOIjrs/f6G4Av+dfb0wOu7/qdcghW
	kasPrPbVVnC+hxp6fgrFyCwDtuOjXr7/oQCQt6GWgQKBgQD9L8UWvAmyaTRGxtUe
	qXhDiimNxBryCrY9Cvam0aN938MgZgFb8SIKUTEOCc+ch0fdOIlVrQnKWhG/wsMs
	qpsrYzm4/vVNlCODLAAUkFLnyKQ7Rfa6Ic8PUCg/+hZsxGc8JzfWyXQ7YpK3Z/Ti
	hyQrTZQSiwuFweDqeejlZn29YQKBgQDwO5inKoKIZ/8ybMr8J32LA3RWvRQs/COO
	/2g9KBkpoH/2oy56A0VMDW9n6e8qENZthYdcr1oTHj6gJkMzGzrQwxLWaIIqXuiO
	Ww/OI8/x5vL5uAugXo2SDEzznS5+BcQ7jpJUDrFB4LK1xntxompwmgTy9pKylavd
	J5s8BtfsswKBgCKfX7JGMIVHQbDUC9zPi/SgdiI+yPiEYntifmw9ir/x03sgt9rc
	1KzYgX7L9KoRDdDApHck3IvhWUAB4DLBecqrHP+hTeY39ctAPxqQyeWMWHkKjjzR
	1sDfU0G8b0icawmcy1QB5HUie7G8843usRGC4FbQ0NiPtLQby69jHprBAoGAXo/G
	7f5QtWriTDIA0LKmu0KX19DDkVWKla45lOEkznrsA+SF1edVrrMXKDswNkm8bg4C
	k1w+w4nDMvz8tLBCPYyb7KPkoNMNDYQQu57jKqAzAlMM2Ip/hX6sB7TWaSrR7HOZ
	a9cAtSLClcTUMI2zAjHOQukGI5/Ar+P4Oe+jrPsCgYAqg1M0mr5LHKkFiLYi7V0C
	VhAa7AmH1u9aIdjx4iAtLGl+fla0uFfryBuoSWkjdO7qtAjKasjNvijZner8WkOm
	jHxqFApGNnYnVSW9Vw/52vHjYxHePXItIzvEcWXqyAKjHGhCQRjNXamxJ/cpe1i3
	N/tNMGekWbEJFZP/ha9piA==
	-----END PRIVATE KEY-----`;

        const headers = {
            typ: "JWT",
            alg: "RS256",
        };
        const iat = Math.floor(Date.now() / 1000); // Issued At in seconds since Unix epoch
        const exp = iat + 60 * 60; // 1hr from now

        const privateKey = await jose.importPKCS8(private_key, headers.alg);

        const jwt = await new jose.SignJWT(payload)
            .setProtectedHeader(headers)
            .setIssuedAt(iat)
            .setExpirationTime(exp)
            .sign(privateKey);

        console.log("Generated JWT:", jwt);

        // substitute 'company' by your Veezoo subdomain
        const veezooUrl = `https://validusfintech.app.veezoo.com?jwt=${jwt}&embedded=true`;

        // Use veezooUrl as needed, maybe redirect or open it in an iframe
        console.log(veezooUrl);
        setUrl(veezooUrl);
        setIsLoading(false);
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div>Loading...</div>
            </div>
        );
    }
    return <iframe src={url} className="w-full h-screen"></iframe>;
};

export default ChatQuery;
