import React, { useEffect, useState } from "react";
import { Table } from "antd";
import moment from "moment";

const GuardianDetails = (data) => {
  const guardianData = data?.data;
  const [guardianTableData, setGuardianTableData] = useState([]);
  const guardians = [
    {
      title: "S. No",
      dataIndex: "sno",
      width: 67,
    },
    {
      title: "Guardian Name",
      dataIndex: "first_name",
      width: 140,
    },
    {
      title: "Relation",
      dataIndex: "relationship_type_id",
      width: 80,
    },
    {
      title: "PAN Number",
      dataIndex: "proof_value",
      width: 120,
    },
    {
      title: "Date of Birth",
      dataIndex: "birth_date",
      width: 90,
    },
  ];

  useEffect(() => {
    const transformAccountData = (data) => {
      return data?.map((ele, index) => ({
        ...ele,
        sno: index + 1,
        birth_date: ele.birth_date
          ? moment(ele.birth_date).format("DD/MM/YYYY").toString()
          : "Not Available",
        first_name: ele.first_name + "          " + ele.last_name,
        proof_value: ele.proof_value ? ele.proof_value : "Not Available",
        relationship_type_id:
          ele.relationship_type_id === 1
            ? "SON"
            : ele.relationship_type_id === 2
            ? "FATHER"
            : ele.relationship_type_id === 3
            ? "MOTHER"
            : ele.relationship_type_id === 4
            ? "WIFE"
            : ele.relationship_type_id === 5
            ? "HUSBAND"
            : ele.relationship_type_id === 6
            ? "COURT APPOINTED"
            : ele.relationship_type_id === 7
            ? "DAUGHTER"
            : "OTHERS",
      }));
    };
    if (guardianData) {
      const updatedGuardianData = transformAccountData(guardianData);
      setGuardianTableData(updatedGuardianData);
    }
  }, []);

  return (
    <>
      {guardianData?.length > 0 && (
        <>
          <h2 style={{ fontSize: "24px", textAlign: "center" }}>
            <u>Guardian Details</u>
          </h2>
          <Table
            style={{
              display: "block",
              justifyContent: "center",
              marginTop: "3%",
              width: "100%",
            }}
            columns={guardians}
            dataSource={guardianTableData}
            pagination={{
              pageSize: 10,
            }}
            bordered
          />
        </>
      )}
    </>
  );
};

export default GuardianDetails;
