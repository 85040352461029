import React from "react";
import { FaMobileAlt } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import mailbox from "../assets/mailbox.png";
import mail from "../assets/email.png";
const Footer = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        marginTop: "3%",
        marginBottom: "2%",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <FaMobileAlt style={{ width: 50, height: 50 }} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontWeight: "bold", fontSize: "16px" }}>Call us</div>
          <div>
            <a
              href="tel:91002 22569"
              style={{ color: "#000", textDecoration: "none" }}
            >
              91002 22569
            </a>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "30%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            gap: "2%",
            width: "100%",
          }}
        >
          <div>
            <img src={mail} style={{ width: 50, height: 50 }} alt="mail" />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontWeight: "bold", fontSize: "16px" }}>Mail us</div>
            <a
              href="mailto:investorcare@validusfintech.com"
              style={{ color: "#000", textDecoration: "underline" }}
            >
              investorcare@validusfintech.com
            </a>
          </div>
        </div>

        <a
          href="https://www.validusfintech.com/contact-us/"
          target="_blank"
          rel="noreferrer"
          style={{ cursor: "pointer", textDecoration: "none", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "9%",
              width: "100%",
            }}
          >
            <div style={{ marginTop: "16%" }}>
              <ImLocation style={{ width: 50, height: 50 }} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "16%",
              }}
            >
              <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                Locate us
              </div>
              <div>Front Office Locator</div>
            </div>
          </div>
        </a>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          gap: "2%",
          width: "25%",
        }}
      >
        <div>
          <img src={mailbox} style={{ width: 50, height: 50 }} alt="mail" />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontWeight: "bold", fontSize: "16px" }}>
            Write to us
          </div>
          <div style={{ marginTop: "10%" }}>
            <div>1st Floor Nathani Towers</div>
            <div>Plot No 42,</div>
            <div>Jayabheri Enclave II, DLF Rd</div>
            <div>Gachibowli, Hyderabad</div>
            <div>Telangana, 500032</div>
            <div>India</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
