import React, { useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { Button, Modal, Checkbox } from "antd";
import axios from "axios";
const DownloadSOA = ({ clientID, fundID, accountNumber }) => {
  const folioNumber = accountNumber;
  const { apiBaseUrl } = useStateContext();
  const [SOALoaded, setSOALoaded] = useState(false);
  const [soaNotAvailable, setSoaNotAvailable] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const onChange = (option) => {
    setSelectedOption(option);
  };

  const handleConvert = () => {
    if (fundID && clientID) {
      setSOALoaded(true);
      axios
        .get(
          `${apiBaseUrl}/VReports/DownloadSOAPDFCAT2/${clientID}/${fundID}/${accountNumber}?flagforPortfolio=${selectedOption}`
        )
        .then((response) => {
          const base64 = response.data.responseData; // Your base64 string
          const type = "application/pdf";
          const sliceSize = 512;
          const byteCharacters = atob(base64);
          const byteArrays = [];
          for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
          ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          const blob = new Blob(byteArrays, { type: type });
          const fileUrl = URL.createObjectURL(blob);
          window.open(fileUrl);
        })
        .catch((error) => {
          setSoaNotAvailable(true);
        })
        .finally(() => {
          setTimeout(() => {
            setSOALoaded(false); // Enable the buttons after the timeout
            setIsModalOpen(false);
          }, 1000); // 1 seconds timeout
        });
    } else {
      Modal.error({
        title: "Error",
        content: "Client or Fund is not Found",
      });
    }
  };

  const onClickSOA = () => {
    if (accountNumber) {
      let smartStatementURL = `https://vlive.d1n3ll0fsz0uqf.amplifyapp.com/?accNum=${folioNumber}`;
      window.open(smartStatementURL);
    } else {
      console.log("Account number is not Found");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "2%" }}>
      <div style={{ width: "14%" }}>
        <h2>
          <u>Personal Details</u>
        </h2>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <Button type="primary" onClick={showModal}>
            Statement of Account
          </Button>
          <Modal
            title="Statement of Account"
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
          >
            <p>Display Portfolio Holder ?</p>
            <Checkbox
              checked={selectedOption === "Y"}
              onChange={() => onChange("Y")}
            >
              Yes
            </Checkbox>
            <Checkbox
              checked={selectedOption === "N"}
              onChange={() => onChange("N")}
            >
              No
            </Checkbox>
            <p>Click on the button below to display the Statement of Account</p>
            {soaNotAvailable ? (
              <p style={{ fontWeight: "bold" }}>
                SOA can't be Generated until Transaction Endorsement
              </p>
            ) : (
              <>
                <Button
                  type="primary"
                  disabled={!selectedOption} // Disable the button when loading
                  onClick={handleConvert}
                  loading={SOALoaded}
                >
                  Display SOA
                </Button>
                <Button
                  style={{ marginLeft: "2%" }}
                  type="primary"
                  disabled={!selectedOption} // Disable the button when loading
                  onClick={onClickSOA}
                >
                  Display Smart Account Statement
                </Button>
              </>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};
export default DownloadSOA;
