import React from "react";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineRoomService } from "react-icons/md";
import { CiVault } from "react-icons/ci";
import { LuNewspaper } from "react-icons/lu";
import { FaRegAddressCard } from "react-icons/fa";

const data = [
  {
    name: "Service Request",
    icon: <MdOutlineRoomService color="#A3030C" size={30} />,
    link: "../ServiceRequests",
  },
  {
    name: "Document Vault",
    icon: <CiVault color="#A3030C" size={30} />,
    link: "../FundDocuments",
  },
  {
    name: "Account Statement",
    icon: <LuNewspaper color="#A3030C" size={30} />,
    link: "../Profile",
  },
  {
    name: "View Profile Information",
    icon: <FaRegAddressCard color="#A3030C" size={30} />,
    link: "../Profile",
  },
];

const Links = () => {
  const location = useLocation();

  return (
    <>
      {/* <div style={{ display: 'flex', flexDirection: 'row', width: '97%' }}>
        {data.map((item, index) => (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} key={index}>
            <Link
              to={item.link}
              style={{ textDecoration: 'none', color: 'black' }}
              className={location.pathname === item.link ? 'active' : ''}
            >
              <div style={{ backgroundColor: '#D5D1F5', alignSelf: 'center', marginLeft: '30%', borderRadius: 50, width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {
                  item.name === 'Account Statement' ?
                  <div style={{ padding: '4%', width: 35, height: 35, color:'black' }}>
                    {item.icon}
                  </div>
                :
                  <div style={{ padding: '4%', width: 40, height: 40, color:'black' }}>
                    {item.icon}
                  </div>
                }
              </div>
              <div style={{ alignSelf: 'center', textAlign: 'center', width: '56%', marginTop: '10%', marginLeft: '15%',}}>
                {item.name}
              </div>
            </Link>
          </div>
        ))}
      </div> */}
      <div style={{ display: "flex", flexDirection: "row", width: "97%" }}>
        {data.map((item, index) => (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
            key={index}
          >
            <Link
              to={item.link}
              style={{ textDecoration: "none", color: "black" }}
              className={location.pathname === item.link ? "active" : ""}
            >
              <div
                style={{
                  backgroundColor: "#fff",
                  alignSelf: "center",
                  marginLeft: "30%",
                  borderRadius: 50,
                  width: 50,
                  height: 50,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>{item.icon}</div>
              </div>
              <div
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  width: "56%",
                  marginTop: "5%",
                  marginLeft: "15%",
                }}
              >
                {item.name}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default Links;
