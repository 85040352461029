import React from "react";
import { BsPersonVcard, BsCardChecklist, BsBank } from "react-icons/bs";
import { FaMailBulk } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { GiCash } from "react-icons/gi";
import { FaWpforms } from "react-icons/fa"

export const addIndianCommas = value => {
  if (value === undefined || value === null || value === '') return value;
  const cleanedText = value.toString().replace(/[^0-9]/g, '');
  return cleanedText.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
};

export const removeIndianCommas = value => {
  if (value === undefined || value === null || value === '') return value;
  if (typeof value === 'number') return value;
  return Number(value.replace(/[^0-9]/g, ''));
};

export const maskedEmail = (email) => {
  if (email === undefined || email === null || email === "") return email;

  const [username, domain] = email.split("@");
  const maskedUsername =
    username.substring(0, 3) + username.substring(3).replace(/./g, "*");

  return maskedUsername + "@" + domain;
};



export const dateManipulator = string => {
  string = string.slice(0, -9);
  let date = string.slice(-2);
  let year = string.substring(0, 4);
  let month = string.substring(5, 7);
  string = date + '/' + month + '/' + year;
  return string;
};

export const amountInWords = (price) => {
  var sglDigit = [
    'Zero',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
  ],
    dblDigit = [
      'Ten',
      'Eleven',
      'Twelve',
      'Thirteen',
      'Fourteen',
      'Fifteen',
      'Sixteen',
      'Seventeen',
      'Eighteen',
      'Nineteen',
    ],
    tensPlace = [
      '',
      'Ten',
      'Twenty',
      'Thirty',
      'Forty',
      'Fifty',
      'Sixty',
      'Seventy',
      'Eighty',
      'Ninety',
    ],
    handle_tens = function (dgt, prevDgt) {
      return 0 === dgt
        ? ''
        : ' ' + (1 === dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
    },
    handle_utlc = function (dgt, nxtDgt, denom) {
      return (
        (0 !== dgt && 1 !== nxtDgt ? ' ' + sglDigit[dgt] : '') +
        (0 !== nxtDgt || dgt > 0 ? ' ' + denom : '')
      );
    };

  var str = '',
    digitIdx = 0,
    digit = 0,
    nxtDigit = 0,
    words = [];
  if (((price += ''), isNaN(parseInt(price)))) str = '';
  else if (parseInt(price) > 0 && price.length <= 10) {
    for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)

      switch (
      ((digit = price[digitIdx] - 0),
        (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
        price.length - digitIdx - 1)
      ) {
        case 0:
          words.push(handle_utlc(digit, nxtDigit, ''));
          break;
        case 1:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 2:
          words.push(
            0 != digit
              ? ' ' +
              sglDigit[digit] +
              ' Hundred' +
              (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2]
                ? ' and'
                : '')
              : '',
          );
          break;
        case 3:
          words.push(handle_utlc(digit, nxtDigit, 'Thousand'));
          break;
        case 4:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 5:
          words.push(handle_utlc(digit, nxtDigit, 'Lakh'));
          break;
        case 6:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 7:
          words.push(handle_utlc(digit, nxtDigit, 'Crore'));
          break;
        case 8:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 9:
          words.push(
            0 != digit
              ? ' ' +
              sglDigit[digit] +
              ' Hundred' +
              (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2]
                ? ' and'
                : ' Crore')
              : '',
          );


      }
    str = words.reverse().join('');
  } else str = '';
  return str;
}


export const list = [
  {
    name: "Email Id. / Mobile No. Updation & Family Declaration",
    caption:
      "Use this online feature to update your Email / Mobile along with family declaration, in all your folios across Taurus serviced Funds.",
    key: 1,
    route: "Update-email",
    icon: <FaMailBulk size={30} color="#680F10" />,
  },
  // {
  //   name: "Email Id. / Mobile No. Updation & Family Declaration Form",
  //   caption:
  //     "Use this form to update your Email / Mobile along with family declaration, in all your folios across Taurus serviced Funds.",
  //   key: 2,
  //   route: "Update-email",
  //   icon: <BsCardChecklist size={30} color="#680F10" />,

  // },
  // {
  //   name: "PAN-based Service Request Form",
  //   caption:
  //     "Update/modify email ID, phone number and KYC status across all your folios in Taurus serviced Mutual Funds with a single service request.",
  //   key: 3,
  //   route: "Update-email",
  //   icon: <BsPersonVcard size={30} color="#680F10" />,
  // },
];

export const banklist = [
  {
    name: "Digital Bank Mandate Change",
    caption:
      "Safe, simple and paper-less method to change your default bank mandate instantly.",
    key: 1,
    route: "Digital-Bank-Mandate-Change",
    icon: <BsBank size={30} color="#680F10" />,
  },
  {
    name: "Registration/ Cancellation of Bank Mandate",
    caption:
      "Register multiple banks, change mandate or delete bank accounts linked to your MF portfolio.",
    key: 2,
    route: "Bank-Mandate-registration&cancellation",
    icon: <BsBank size={30} color="#680F10" />,

  },
  // {
  //   name: "Bank Mandate with Banker Attestation",
  //   caption:
  //     "To update signature, fill and submit this form with banker attestation at the nearest Taurus Service Centre.",
  //   key: 3,
  //   route: "Bank-Mandate-with-Banker-Attestation",
  //   icon: <BsBank size={30} color="#680F10" />,
  // },
  // {
  //   name: "Bank Merger - Digital Bank Mandate Change",
  //   caption:
  //     "Hassle free, paperless solution for investors to change bank mandate after a bank merger.",
  //   key: 4,
  //   route: "Bank-Mergers-cobm",
  //   icon: <BsBank size={30} color="#680F10" />,
  // },
  // {
  //   name: "About Bank Merger",
  //   caption:
  //     "There has been a series of Public Sector bank mergers pursuant to Govt. of India's initiative to consolidate the public sector banking system since 2019/20.",
  //   key: 5,
  //   route: "Bank-Mergers",
  //   icon: <BsBank size={30} color="#680F10" />,
  // },
];

export const nomineeList = [
  {
    name: "Nomination Opt-in / Opt-out - Online Mode",
    caption:
      "Use this online feature to declare nominee(s) or to opt-out nomination for your Mutual Fund folio(s) through OTP based authentication process.",
    key: 1,
    route: "Nomination-Opt-in_&_Opt-out",
    icon: <FaPeopleGroup size={30} color="#680F10" />,
  },
  // {
  //   name: "Nomination Opt-in / Opt-out - Physical Form",
  //   caption:
  //     "Use this form to register or cancel a nomination in your folio in any of the Taurus serviced Mutual Funds.",
  //   key: 2,
  //   route: "Nomination_Opt-in_or_Opt-out",
  //   icon: <FaPeopleGroup size={30} color="#680F10" />,
  // },
];


export const checkList = [
  {
    name: "Enter PAN & AMC details",
    key: 1,
  },
  {
    name: "Enter Mobile No / Email ID",
    key: 2,
  },
  {
    name: "Enter OTP & validate",
    key: 3,
  },
  {
    name: "Select Folio & Mandate to be changed",
    key: 4,
  },
  {
    name: "Upload Old & new bank cheques",
    key: 5,
  },
  {
    name: "Give Consent & submit",
    key: 6,
  },
]


export const quickServicesList = [
  {
    name: "Change of Bank Mandate",
    caption:
      "Safe, simple and paper-less method to change your default bank mandate instantly.",
    key: 1,
    route: "Change-Bank-Mandate",
    icon: <BsBank size={26} color="#680F10" />,
  },
  {
    name: "Updation of Email Id. / Mobile No.",
    caption:
      "Use this online feature to update your own Email Id / Mobile number in all your folios across Validus serviced Funds.",
    key: 2,
    route: "Updation_Email_Id_or_Mobile_No",
    icon: <FaMailBulk size={26} color="#680F10" />,
  },
  {
    name: "Nomination",
    caption:
      "Use this online feature to declare nominee(s) or to opt-out nomination for your Mutual Fund folio(s) through OTP based authentication process.",
    key: 3,
    route: "Nomination",
    icon: <FaPeopleGroup size={26} color="#680F10" />,
  },
  {
    name: "GoGreen",
    caption:
      "Use this online feature to declare nominee(s) or to opt-out nomination for your Mutual Fund folio(s) through OTP based authentication process.",
    key: 4,
    route: "GoGreen",
    icon: <FaWpforms size={26} color="#680F10" />,
  },
  // {
  //   name: "eNACH / eMandate",
  //   caption:
  //     "PAN based TaurusPay eMandate for any number of lumpsum purchases and SIPs across Taurus serviced Funds.",
  //   key: 5,
  //   route: "eNACH-eMandate",
  //   icon: <GiBank size={26} color="#680F10" />,
  // },
  // {
  //   name: "Transmission",
  //   caption:
  //     "Learn about the transmission process and the documents requirement.",
  //   key: 6,
  //   route: "Transmission",
  //   icon: <GiCash size={26} color="#680F10" />,
  // },
];


export const links = [
  {
    links: [
      {
        displayName: "Change of Bank Mandate",
        name: "ServiceRequests/Change-Bank-Mandate",
        key: 1,
      },
      {
        displayName: "Updation of Email Id. / Mobile No.",
        name: "ServiceRequests/Updation_Email_Id_or_Mobile_No",
        key: 2,
      },
      {
        displayName: "Nomination",
        name: "ServiceRequests/Nomination",
        key: 3,
      },
      {
        displayName: "GoGreen",
        name: "ServiceRequests/GoGreen",
        key: 4,
      },
      // {
      //   displayName: "eNACH / eMandate",
      //   name: "ServiceRequests/eNACH-eMandate",
      //   key: 5,
      // },
      // {
      //   displayName: "Transmission",
      //   name: "ServiceRequests/Transmission",
      //   key: 6,
      // },
    ],
  },
];

export const goGreen = [
  {
    name: "Email Id. / Mobile No. Updation & Family Declaration",
    caption:
      "Use this online feature to update your Email / Mobile along with family declaration, in all your folios across Taurus serviced Funds.",
    key: 1,
    route: "Update-email",
    icon: <FaMailBulk size={30} color="#680F10" />,
  },
  // {
  //   name: "Updation of PAN",
  //   caption:
  //     "SEBI has mandated for PAN availability in folios. Here is a quick way to update of PAN.",
  //   key: 2,
  //   route: "Pan-updation",
  //   icon: <BsCardChecklist size={30} color="#680F10" />,

  // },
  // {
  //   name: "Opt-In / Opt-Out for Statements via Email",
  //   caption:
  //     "Register to obtain annual reports or abridged summaries in physical format.",
  //   key: 3,
  //   route: "Opt-In_Or_Opt-Out_for_Statements",
  //   icon: <BsPersonVcard size={30} color="#680F10" />,
  // },
  {
    name: "FATCA/CRS",
    caption:
      "Update your MF folios with FATCA/CRS and supplementary KYC information.",
    key: 4,
    route: "FATCA&CRS",
    icon: <FaWpforms size={26} color="#680F10" />,
  },
  // {
  //   name: "FORM 15G/H",
  //   caption:
  //     "Request form to stop TDS deduction on IDCW pay-out",
  //   key: 5,
  //   route: "Form15G-H",
  //   icon: <GiBank size={26} color="#680F10" />,
  // },
  {
    name: "Unclaimed IDCW/redemption status",
    caption:
      "View details of your unclaimed IDCW/redemption amounts across Taurus serviced Mutual Funds.",
    key: 6,
    route: "Unclaimed-redemption&IDCW",
    icon: <GiCash size={26} color="#680F10" />,
  },
];
export const eNachList = [
  {
    name: "TaurusPay eMandate",
    caption:
      "PAN based TaurusPay eMandate for any number of lumpsum purchases and SIPs across Taurus serviced Funds.",
    key: 1,
    route: "Update-email",
    icon: <FaMailBulk size={30} color="#680F10" />,
  },
  {
    name: "eNACH/OTM Cancellation",
    caption:
      "PAN based eNACH/OTM Cancellation facility to cancel OTM registered with Taurus or Taurus serviced Mutual Funds.",
    key: 2,
    route: "Update-email",
    icon: <BsCardChecklist size={30} color="#680F10" />,

  },
]

export const transmissionList = [
  {
    name: "About Transmission",
    caption:
      "Learn about the transmission process and the documents requirement.",
    key: 1,
    route: "Update-email",
    icon: <FaMailBulk size={30} color="#680F10" />,
  },
  {
    name: "Transmission Request Form",
    caption:
      "Fill, print and submit this form at the nearest Taurus Service Centre.",
    key: 2,
    route: "Update-email",
    icon: <BsCardChecklist size={30} color="#680F10" />,

  },
  {
    name: "Supporting Documents and Annexures",
    caption:
      "Library of supporting annexures.",
    key: 3,
    route: "Update-email",
    icon: <BsPersonVcard size={30} color="#680F10" />,
  }
]