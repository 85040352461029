import React from "react";
import moment from "moment";
const HolderDetails = (filteredData) => {
  const { firstHolderData, folioNumber } = filteredData;
  const InfoItem = ({ label, value }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-betwen",
      }}
    >
      <p style={{ width: "35%", textAlign: "left" }}>{label}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "65%",
        }}
      >
        <div>:</div>
        <div style={{ marginLeft: "3%", textAlign: "left" }}>
          <b>{value}</b>
        </div>
      </div>
    </div>
  );
  const DateInfoItem = ({ label, date }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-betwen",
      }}
    >
      <p style={{ width: "35%", textAlign: "left" }}>{label}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "65%",
        }}
      >
        <div>:</div>
        <div style={{ marginLeft: "3%" }}>
          <b>
            {" "}
            {date
              ? new Date(date).toLocaleDateString("en-GB")
              : "Not Available"}
          </b>
        </div>
      </div>
    </div>
  );

  const isProofValueValid =
    firstHolderData[0]?.prooflist[0]?.proof_value?.charAt(3) !== "P";
  const networthValue = firstHolderData[0]?.account_Holder?.networth_value;

  return (
    //  First Holder Details
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2%",
            width: "50%",
          }}
        >
          <InfoItem
            label="Name"
            value={`${firstHolderData[0]?.account_Holder?.first_name} `}
          />
          {!isProofValueValid ? (
            <InfoItem
              label="Gender"
              value={
                firstHolderData[0]?.account_Holder?.gender_id === 1
                  ? "Male"
                  : firstHolderData[0]?.account_Holder?.gender_id === 2
                  ? "Female"
                  : "Others"
              }
            />
          ) : null}
          <InfoItem label="Account Number" value={folioNumber} />

          {isProofValueValid ? (
            <DateInfoItem
              label="Date of Incorporation"
              date={
                firstHolderData[0]?.account_Holder
                  ?.institution_incorporation_date
              }
            />
          ) : (
            <DateInfoItem
              label="Date of birth"
              date={firstHolderData[0]?.account_Holder?.dob}
            />
          )}
          <InfoItem
            label="Phone Number"
            value={firstHolderData[0]?.account_Holder?.primary_phone_number}
          />
          <InfoItem
            label="PAN Number"
            value={
              firstHolderData[0]?.prooflist &&
              firstHolderData[0]?.prooflist[0]?.proof_value ? (
                <span>{firstHolderData[0]?.prooflist[0]?.proof_value}</span>
              ) : (
                <span>Not Available</span>
              )
            }
          />
          <InfoItem
            label="Email"
            value={firstHolderData[0]?.account_Holder?.primary_email_id}
          />

          <InfoItem
            label="Networth"
            value={networthValue ? `INR ${networthValue}` : "Not Available"}
          />
          <InfoItem
            label="Networth as on"
            value={
              firstHolderData[0]?.account_Holder?.networth_date !== null || ""
                ? moment(firstHolderData[0]?.account_Holder?.networth_date)
                    .format("DD/MM/YYYY")
                    .toString()
                : "Not Available"
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2%",
            width: "50%",
          }}
        >
          <InfoItem
            label="Address Line 1"
            value={firstHolderData[0]?.addrdata?.address_line1}
          />
          <InfoItem
            label="Address Line 2"
            value={`${firstHolderData[0]?.addrdata?.address_line2}, ${firstHolderData[0]?.addrdata?.address_line3}`}
          />
          <InfoItem label="City" value={firstHolderData[0]?.addrdata?.city} />
          <InfoItem
            label="District"
            value={firstHolderData[0]?.addrdata?.district_county}
          />
          <InfoItem
            label="State"
            value={
              firstHolderData[0]?.addrdata?.state_province_text ? (
                <span>{firstHolderData[0]?.addrdata?.state_province_text}</span>
              ) : (
                <span>Not Available</span>
              )
            }
          />
          <InfoItem
            label="Pincode"
            value={firstHolderData[0]?.addrdata?.pincode}
          />
          <InfoItem
            label="Secondary Email"
            value={
              firstHolderData[0]?.account_Holder?.secondary_email_id ? (
                <span>
                  {firstHolderData[0]?.account_Holder?.secondary_email_id}
                </span>
              ) : (
                <span>Not Available</span>
              )
            }
          />
          <InfoItem
            label="Secondary Mobile"
            value={
              firstHolderData[0]?.account_Holder?.secondary_phone_number ? (
                <span>
                  {firstHolderData[0]?.account_Holder?.secondary_phone_number}
                </span>
              ) : (
                <span>Not Available</span>
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default HolderDetails;
