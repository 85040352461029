import React, { useState } from "react";
import "antd/dist/antd.min.css";
import { useStateContext } from "../../contexts/ContextProvider";
import DownloadSOA from "../../components/DownloadSOA";
import "./Profile.css";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import DrawdownDetails from "./DrawdownDetails";
import BankDetails from "./BankDetails";
import GuardianDetails from "./GuardianDetails";
import HolderDetails from "./HolderDetails";
import NomineeDetails from "./NomineeDetails";
import ServiceRequestDetails from "./ServiceRequestDetails";
import TransactionDetails from "./TransactionDetails";
import DocumentDetails from "./DocumentDetails";
import SecondHolderDetails from "./SecondHolderDetails";
import ThirdHolderDetails from "./ThirdHolderDetails";
import { Select, Button } from "antd";

const Profile = () => {
  const { apiBaseUrl, folioData, setActiveMenu } = useStateContext();
  const [profData, setProfData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nctData, setNctData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [IFSCData, setIFSCData] = useState([]);
  const [drawdownData, setDrawdownData] = useState([]);
  const [toggleState, setToggleState] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [accountNomineeData, setAccountNomineeData] = useState([]);
  const [jointHolder1Data, setJointHolder1Data] = useState([]);
  const [jointHolder2Data, setJointHolder2Data] = useState([]);
  const [accountGuardianData, setAccountGuardianData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [clientId, setClientId] = useState("");
  const [fundId, setFundId] = useState("");
  const [showProfile, setShowProfile] = useState(false);
  const [disableGetButton, setDisableGetButton] = useState(true);
  const [ihNumbersData, setIhNumbersData] = useState([]);
  const [folioNumber, setFolioNumber] = useState("");
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const onChangeFolio = (value) => {
    setDisableGetButton(false);
    const selectedData = folioData.find(
      (item) => item.account_number === value
    );
    setFolioNumber(selectedData.account_number);
  };

  const fetchData = async () => {
    setLoading(true);
    setShowProfile(true);
    setDisableGetButton(false);
    setActiveMenu(false);
    try {
      const resp = await axios.get(
        `${apiBaseUrl}/VAccount/Getvadiledaccount/${folioNumber}`
      );
      const res = await axios.get(
        `${apiBaseUrl}/VAccount/GetProfilebyAccnum?Accountnum=${folioNumber}`
      );
      const profileResData = await resp.data?.responseData;
      const resData = await res.data;
      if (res.data.code === -100) {
        return; // Return early to stop further processing
      }
      const accountData = resData.responseData?.accountHolderdata;
      const bankData = resData.responseData?.bankdata;
      const guardianData = resData.responseData?.guardianlist;
      const accountNomineeData = resData.responseData?.nomineelist;
      const jointHolder1Data = resData.responseData?.accountHolderdata.filter(
        (obj) => obj.account_Holder.account_holder_number === 2
      );
      const jointHolder2Data = resData.responseData?.accountHolderdata.filter(
        (obj) => obj.account_Holder.account_holder_number === 3
      );
      const fundId = resData.responseData?.classdata[0]?.fund_id;
      const clientId = resData.responseData?.classdata[0]?.client_id;

      const [nctResponse, drawdownResponse, transactionResponse, ifscResponse] =
        await Promise.all([
          axios.get(
            `${apiBaseUrl}/VNct/GetNCTbyAccountNumber/${folioNumber}/${clientId}`
          ),
          axios.get(
            `${apiBaseUrl}/VDrawdown/FetchDrawdownDetails/${clientId}?accountnumber=${folioNumber}`
          ),
          axios.get(
            `${apiBaseUrl}/VTransaction/GetTransactionsCommon/${clientId}?accountnumber=${folioNumber}&rejecttxn=true`
          ),
          axios.get(
            `${apiBaseUrl}/Vlookup/Rbidetails/${bankData?.accountbankdata?.bank_ifsc}`
          ),
        ]);

      const nctTrxnData = await nctResponse.data.responseData;
      const drawdownData = await drawdownResponse.data.responseData;
      const transactionsData = await transactionResponse.data.responseData;
      const ifscData = ifscResponse.data.responseData;

      const transactionIhnumbers = await transactionsData
        .map((ele) => [ele.ih_number])
        .flat();
      const nctIhNumbers = await nctTrxnData
        .map((ele) => [ele.nct_ih_number])
        .flat();
      const ihNumbers = [...transactionIhnumbers, ...nctIhNumbers];

      setFilteredData(
        accountData.filter(
          (obj) => obj.account_Holder.account_holder_number === 1
        )
      );
      setBankData(bankData);
      setAccountGuardianData(guardianData);
      setAccountNomineeData(accountNomineeData);
      setJointHolder1Data(jointHolder1Data);
      setJointHolder2Data(jointHolder2Data);
      setFundId(fundId);
      setClientId(clientId);
      setNctData(nctTrxnData);
      setDrawdownData(drawdownData);
      setTransactionData(transactionsData);
      setIFSCData(ifscData);
      setLoading(false);
      setIhNumbersData(ihNumbers);
      setProfData(profileResData);
    } catch (error) {
      alert("something went wrong");
      setLoading(false);
    }
  };
  return (
    <>
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          fontSize: "30px",
          color: "#680F10",
        }}
      >
        Profile Details
      </h1>
      <div style={{ margin: "0 auto", width: "70%", alignSelf: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 70,
          }}
        >
          <Select
            showSearch
            style={{
              fontSize: "14px",
              color: "#680F10",
              width: "200px",
            }}
            placeholder="Select the Folio"
            optionFilterProp="children"
            onChange={(e) => onChangeFolio(e)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {folioData?.map((item) => (
              <Select.Option value={item.account_number}>
                {item.account_number}
              </Select.Option>
            ))}
          </Select>
          <Button
            disabled={disableGetButton}
            onClick={fetchData}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "140px",
              height: "33px",
              backgroundColor: "#680F10",
              color: "white",
              borderRadius: 5,
            }}
          >
            Get
          </Button>
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              width: "140px",
              height: "33px",
              backgroundColor: "#680F10",
              color: "white",
              borderRadius: 5,
            }}
            onClick={() => window.location.reload(false)}
          >
            Reset
          </Button>
        </div>
      </div>
      {showProfile ? (
        <>
          {loading ? (
            <div>
              <LoadingScreen
                loading={true}
                bgColor="transparent"
                spinnerColor="#000"
                textColor="#000"
                text="Loading..."
              >
                <h1>Loading...</h1>
              </LoadingScreen>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                margin: "0 auto",
                marginTop: "4%",
                marginBottom: "4%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                width: "98%",
                height: "70%",
              }}
            >
              <div className="container">
                <div className="bloc-tabs">
                  <button
                    className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(1)}
                  >
                    Holder{"\n"}Details
                  </button>
                  <button
                    className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(2)}
                  >
                    Bank Details
                  </button>
                  <button
                    className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(3)}
                  >
                    Transactions
                  </button>
                  <button
                    className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(4)}
                  >
                    Drawdowns
                  </button>
                  <button
                    className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(5)}
                  >
                    Service Requests
                  </button>
                  <button
                    className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(6)}
                  >
                    Interaction History
                  </button>
                </div>
                <div className="content-tabs">
                  {/* Holder Details */}
                  <div
                    className={
                      toggleState === 1 ? "content  active-content" : "content"
                    }
                  >
                    {fundId && clientId && (
                      <DownloadSOA
                        clientID={clientId}
                        fundID={fundId}
                        accountNumber={folioNumber}
                      />
                    )}
                    <HolderDetails
                      firstHolderData={filteredData}
                      folioNumber={folioNumber}
                    />
                    <SecondHolderDetails secondHolderData={jointHolder1Data} />
                    <ThirdHolderDetails thirdHolderData={jointHolder2Data} />
                    <GuardianDetails guardianData={accountGuardianData} />
                    <NomineeDetails nomineeData={accountNomineeData} />
                  </div>

                  {/* Bank Details */}
                  <div
                    className={
                      toggleState === 2 ? "content  active-content" : "content"
                    }
                  >
                    <BankDetails
                      data={filteredData}
                      bankData={bankData}
                      ifscData={IFSCData}
                    />
                  </div>

                  {/* Transaction Details */}
                  <div
                    className={
                      toggleState === 3 ? "content  active-content" : "content"
                    }
                  >
                    <TransactionDetails data={transactionData} />
                  </div>

                  {/* Drawdown Details */}
                  <div
                    className={
                      toggleState === 4 ? "content  active-content" : "content"
                    }
                  >
                    <DrawdownDetails data={drawdownData} />
                  </div>

                  {/* Service Requests */}
                  <div
                    className={
                      toggleState === 5 ? "content  active-content" : "content"
                    }
                  >
                    <ServiceRequestDetails data={nctData} />
                  </div>

                  {/* Investor Documents */}
                  <div
                    className={
                      toggleState === 6 ? "content  active-content" : "content"
                    }
                  >
                    <DocumentDetails
                      data={ihNumbersData}
                      investorName={filteredData[0]?.account_Holder?.legal_name}
                      classCategory={profData[0]?.class_name}
                      fundName={profData[0]?.fund_name}
                      clientName={profData[0]?.client_name}
                      folioNumber={folioNumber}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
          role="alert"
        >
          Please Select Folio Number
        </div>
      )}
    </>
  );
};
export default Profile;
