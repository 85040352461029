import React from "react";
import apple from "../assets/apple.png";
import googlePlay from "../assets/download.png";

const AppIcons = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
      }}
    >
      <div
        style={{
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a
          href="https://www.apple.com/in/app-store/"
          className="neomorphism-button"
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <img
              src={apple}
              style={{
                width: "30px",
                height: "30px",
                marginRight: "10px",
                marginTop: "13px",
              }}
              alt="apple"
            />
            <div>
              <div style={{ fontSize: 12 }}>Download on the</div>
              <div>App Store</div>
            </div>
          </div>
        </a>
      </div>
      <div
        style={{
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a href="https://play.google.com" className="neomorphism-button">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <img
              src={googlePlay}
              style={{
                width: "30px",
                height: "30px",
                marginRight: "10px",
                marginTop: "13px",
              }}
              alt="googlePlay"
            />
            <div>
              <div style={{ fontSize: 12 }}>GET IT ON</div>
              <div>Google Play</div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default AppIcons;
