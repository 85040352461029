import React, { useState } from "react";
import "antd/dist/antd.min.css";
import { useStateContext } from "../../contexts/ContextProvider";
import "../Profile/Profile.css";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import DrawdownDetails from "../Profile/DrawdownDetails";
import ServiceRequestDetails from "../Profile/ServiceRequestDetails";
import TransactionDetails from "../Profile/TransactionDetails";
import { Select, Button } from "antd";

const Transactions = () => {
  const onChangeFolio = (value) => {
    setDisableGetButton(false);
    const selectedData = folioData.find(
      (item) => item.account_number === value
    );
    setFolioNumber(selectedData.account_number);
  };

  const { apiBaseUrl, folioData, setIsCrashed, setActiveMenu } =
    useStateContext();

  const [loading, setLoading] = useState(true);
  const [nctData, setNctData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [drawdownData, setDrawdownData] = useState([]);
  const [toggleState, setToggleState] = useState(1);
  const [showProfile, setShowProfile] = useState(false);
  const [disableGetButton, setDisableGetButton] = useState(true);
  const [folioNumber, setFolioNumber] = useState("");
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const fetchData = async () => {
    setActiveMenu(false);
    setShowProfile(true);
    setDisableGetButton(false);
    try {
      const res = await axios.get(
        `${apiBaseUrl}/VAccount/GetProfilebyAccnum?Accountnum=${folioNumber}`
      );
      const resData = await res.data;
      if (res.data.code === -100) {
        setIsCrashed(true);
        return; // Return early to stop further processing
      }
      const clientId = resData.responseData?.classdata[0]?.client_id;

      const [nctResponse, drawdownResponse, transactionResponse] =
        await Promise.all([
          axios.get(
            `${apiBaseUrl}/VNct/GetNCTbyAccountNumber/${folioNumber}/${clientId}`
          ),
          axios.get(
            `${apiBaseUrl}/VDrawdown/FetchDrawdownDetails/${clientId}?accountnumber=${folioNumber}`
          ),
          axios.get(
            `${apiBaseUrl}/VTransaction/GetTransactionsCommon/${clientId}?accountnumber=${folioNumber}&rejecttxn=true`
          ),
        ]);

      const nctData = await nctResponse.data.responseData;
      const drawdownData = await drawdownResponse.data.responseData;
      const transactionsData = await transactionResponse.data.responseData;
      setNctData(nctData);
      setDrawdownData(drawdownData);
      setTransactionData(transactionsData);
      setLoading(false);
    } catch (error) {
      alert("something went wrong");
    }
  };
  return (
    <>
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          fontSize: "30px",
          color: "#680F10",
        }}
      >
        All Transactions
      </h1>
      <div style={{ margin: "0 auto", width: "70%", alignSelf: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 70,
          }}
        >
          <Select
            showSearch
            style={{
              fontSize: "14px",
              color: "#680F10",
              width: "200px",
            }}
            placeholder="Select the Folio"
            optionFilterProp="children"
            onChange={(e) => onChangeFolio(e)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {folioData?.map((item) => (
              <Select.Option value={item.account_number}>
                {item.account_number}
              </Select.Option>
            ))}
          </Select>
          <Button
            disabled={disableGetButton}
            onClick={fetchData}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "140px",
              height: "33px",
              backgroundColor: "#680F10",
              color: "white",
              borderRadius: 5,
            }}
          >
            Get
          </Button>
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              width: "140px",
              height: "33px",
              backgroundColor: "#680F10",
              color: "white",
              borderRadius: 5,
            }}
            onClick={() => window.location.reload(false)}
          >
            Reset
          </Button>
        </div>
      </div>
      {showProfile ? (
        <>
          {loading ? (
            <div>
              <LoadingScreen
                loading={true}
                bgColor="transparent"
                spinnerColor="#000"
                textColor="#000"
                text="Loading..."
              >
                <h1>Loading...</h1>
              </LoadingScreen>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                margin: "0 auto",
                marginTop: "4%",
                marginBottom: "4%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                width: "98%",
                height: "70%",
              }}
            >
              <div className="container">
                <div className="bloc-tabs">
                  <button
                    className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(1)}
                  >
                    Transactions
                  </button>
                  <button
                    className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(2)}
                  >
                    Drawdowns
                  </button>
                  <button
                    className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(3)}
                  >
                    Service Requests
                  </button>
                </div>
                <div className="content-tabs">
                  {/* Transaction Details */}
                  <div
                    className={
                      toggleState === 1 ? "content  active-content" : "content"
                    }
                  >
                    <TransactionDetails data={transactionData} />
                  </div>

                  {/* Drawdown Details */}
                  <div
                    className={
                      toggleState === 2 ? "content  active-content" : "content"
                    }
                  >
                    <DrawdownDetails data={drawdownData} />
                  </div>

                  {/* Service Requests */}
                  <div
                    className={
                      toggleState === 3 ? "content  active-content" : "content"
                    }
                  >
                    <ServiceRequestDetails data={nctData} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
          role="alert"
        >
          Please Select Folio Number
        </div>
      )}
    </>
  );
};
export default Transactions;
