import React, { useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import logo from "../assets/validuslogo.png";
import { useStateContext } from "../contexts/ContextProvider";

const Navbar = () => {
  const { activeMenu, setActiveMenu, screenSize, setScreenSize } =
    useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [setScreenSize]);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu]);

  const NavButton = ({ title, customfunc, icon, color, dotColor }) => (
    <Tooltip title={title} placement="bottom">
      <button
        type="button"
        onClick={customfunc}
        style={{ color }}
        className="relative text-xl rounded-full p-3 hover:bg-light-gray"
      >
        <span
          style={{ background: dotColor }}
          className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
        />
        {icon}
      </button>
    </Tooltip>
  );
  return (
    <div
      className="flex justify-between 
    md:mx-6 relative"
      style={{ backgroundColor: "#F1F6F9" }}
    >
      <NavButton
        title="Menu"
        customfunc={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
        color="black"
        icon={<AiOutlineMenu />}
      />
      <Link to="/Dashboard">
        <img
          style={{ width: 120 }}
          className={`ml-32 ${activeMenu ? "invisible" : ""}`}
          src={logo}
          alt="company logo"
        />
      </Link>
    </div>
  );
};

export default Navbar;
